<template>
  <div>
    <div class="row align-items-center mt-4">
            <div class="col-md-6">
              <div class="w-50">
                <span class="fw-semibold font-size-2">Cottages List </span>
              </div>
            </div>
            <div class="col-md-6 text-end">
              <div>
                <router-link
                  :to="
                    '/farm-stay/retreat/' +
                    $route.params.retreat_id +
                    '/cottages/create'
                  "
                  class="btn bg-primary text-white font-size-1"
                >
                  Add Cottage</router-link
                >
              </div>
            </div>
          </div>
          <div v-if="!isLoading">
            <div class="row mt-5 mx-1">
              <table class="table font-size-1">
                <thead>
                  <tr class="bg-white shw">
                    <th scope="col">Cottage Name</th>
                    <th scope="col">No of cottages</th>
                    <th scope="col">Price on weekdays</th>
                    <th scope="col">Price on weekends</th>
                    <th scope="col">Price on special days</th>
                    <th scope="col">Guest fee</th>
                    <th scope="col">Members allowed</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody class="fw-medium font-size-1">
                  <template v-if="cottages.length > 0">
                    <tr
                      class="bg-white shw"
                      v-for="(cottage, i) in cottages"
                      :key="i"
                    >
                      <td class="text-gray-300">{{ cottage.cottage_name }}</td>
                      <td class="text-gray-300">{{ cottage.cottage_count }}</td>
                      <td class="text-gray-300">{{ cottage.weekdays_price }}</td>
                      <td class="text-gray-300">{{ cottage.weekends_price }}</td>
                      <td class="text-gray-300">
                        {{ cottage.special_days_price }}
                      </td>
                      <td class="text-gray-300">{{ cottage.guest_fee }}</td>
                      <td class="text-gray-300">
                        {{ cottage.max_member_allowed }}
                      </td>
                      <td>
                        <router-link
                          :to="
                            '/farm-stay/retreat/' +
                            this.$route.params.retreat_id +
                            '/cottages/' +
                            cottage._id +
                            '/edit'
                          "
                        >
                          <i
                            class="fa fa-pencil rounded-circle bg-gray-100 p-1"
                            aria-hidden="true"
                          ></i>
                        </router-link>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="8" class="text-center">No Records</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else>Loading...</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cottages: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getCottages();
  },
  methods: {
    getCottages() {
      let retreat_id = this.$route.params.retreat_id;
      console.log(retreat_id);
      this.isLoading= true;
      this.$axios
        .get("/farm-stay/retreat/" + retreat_id + "/cottage")
        .then((resp) => {
          console.log(resp);
          this.isLoading= false;
          this.cottages = resp.data.data;
        });
    },
  },
};
</script>