import { ref, computed } from 'vue'
import moment from "moment";


export default function dateUtilities(){

    const dateAndTime =  (value)  => {
        if (value) return moment(value).format("DD-MM-YYYY HH:MM:SS");
        else return "";
      }
      const dateFormat =   (value)  => {
        if (value) return moment(value).format("DD-MM-YYYY");
        else return "";
      }
      return {
        dateAndTime,
        dateFormat
      }
}