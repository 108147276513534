<template>
  <ul class="list-unstyled mb-0 fw-medium mt-3">
    <router-link
      to="/farm-stay/retreat/bookings"
      class="text-black text-decoration-none ps-2"
    >
      <li class="p-3" :class="{ active: $route.path.includes('farm-stay') }">
        Farm Stay
      </li>
    </router-link>
     <!-- <router-link to="/activities" class="text-black text-decoration-none ps-2">
      <li class="p-3" :class="{ active: $route.name.includes('ActivitiesList') }">
        Activities 
      </li>
    </router-link> -->
    <router-link
      to="/reports/activities"
      class="text-black text-decoration-none ps-2"
    >
      <li class="p-3" :class="{ active: $route.path.includes('reports') }">
        Reports
      </li>
    </router-link>
  </ul>
</template>
<style>

</style>