<template>
  <div>
    <div class="row mt-2 mb-2">
      <div class="col-md-3">
        <Select2
          v-model="forms.query"
          :options="cofarmer_details"
          @change="myChangeEvent($event)"
          id="rtrtBkLstMbr"
          placeholder="Select member"
          @select="mySelectEvent($event)"
          class="form-select2"
          :settings="{
            allowClear: true,
          }"
        >
        </Select2>
      </div>
      <div class="col-md-3">
        <Select2
          v-model="forms.retreat_id"
          :options="retreats"
          class="form-select2"
          placeholder="Select Retreat"
          id="rtrtBkLstRtrt"
          :settings="{
            allowClear: true,
          }"
        >
        </Select2>
      </div>
      <div class="col-md-3">
        <div class="date">
          <VueDatePicker
            v-model="forms.dates"
            :enable-time-picker="false"
            range
            placeholder="Select CheckIn Date"
            position="left"
            auto-apply
          ></VueDatePicker>
        </div>
      </div>
      <div class="col-md-3">
        <Select2
            v-model="forms.bookingStatus"
            :options="[
              { id: 1, text: 'CONFIRMED'},
              { id: 2, text: 'BLOCKED'},
              { id: 3, text: 'CANCELLED'},
              { id: 4, text: 'PAYMENT FAILED'}
            ]"
            placeholder="Select Status"
            id="bookingStatusId"
            :settings="{
              allowClear: true,
            }"

          >
          </Select2>

      </div>
       <div class="col-md-3 mt-2">
         <Select2
            v-model="forms.bookingSource"
            :options="[
              { id: 1, text: 'Admin'},
              { id: 2, text: 'Mobile'}
            ]"

            placeholder="Select Booking Source"
            id="bookingSourceId"
            :settings="{
              allowClear: true,
            }"

          >
          </Select2>
       </div>

       <div class="col-md-3   mt-2">
          <input type="text" class="form-control" placeholder="Search by Booking id" v-model="forms.bookingID">
       </div>

      <div class="col-md-3 mt-2">
        <button
          class="bg-primary px-3 py-2 rounded-1 border-0 mr-2"
          @click="getData"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Vector"
              d="M12.7549 11.2549H11.9649L11.6849 10.9849C12.6649 9.84488 13.2549 8.36488 13.2549 6.75488C13.2549 3.16488 10.3449 0.254883 6.75488 0.254883C3.16488 0.254883 0.254883 3.16488 0.254883 6.75488C0.254883 10.3449 3.16488 13.2549 6.75488 13.2549C8.36488 13.2549 9.84488 12.6649 10.9849 11.6849L11.2549 11.9649V12.7549L16.2549 17.7449L17.7449 16.2549L12.7549 11.2549ZM6.75488 11.2549C4.26488 11.2549 2.25488 9.24488 2.25488 6.75488C2.25488 4.26488 4.26488 2.25488 6.75488 2.25488C9.24488 2.25488 11.2549 4.26488 11.2549 6.75488C11.2549 9.24488 9.24488 11.2549 6.75488 11.2549Z"
              fill="white"
            />
          </svg>
        </button>
        <JsonToCSV :data="csv_data" v-if="csv_data.length > 0"></JsonToCSV>
      </div>
    </div>
    <hr class="mt-0" />
    <div v-if="!isLoading">
      <div class="table-responsive">
        <table class="table text-center">
          <thead class="font-size-12">
            <tr>
              <th>Booking ID</th>
              <!-- <th>Customer Name</th> -->
              <th>Retreat</th>
              <th>Cottage Name</th>
              <th>Cottage Count</th>
              <th>Check In</th>
              <th>Check out</th>
              <th>Days & Nights</th>
              <th>Booked by</th>
              <!-- <th>Booking date</th> -->
              <th>Status</th>
              <th>Credits used</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            <tr class="text-muted" v-for="(d, i) in data" :key="i">
              <template v-if="d._farm_retreat_id">
                <td>{{ d.bookingId }}</td>

                <td>{{ d._farm_retreat_id.display_name }}</td>
                <td>{{ d._farm_retreat_cottage_id.cottage_name }}</td>
                <td>{{ d.cottage_count }}</td>
                <td>{{ dateFormat(d.check_in) }}</td>
                <td>{{ dateFormat(d.check_out) }}</td>
                <td>{{ d.day_counts }}D {{ d.night_counts }}N</td>

                <!-- <td>{{ d.created_by.name }}</td> -->
                <td v-if="d.user_id">{{ d.user_id.name }}</td>
                <td v-else>--</td>
                <!-- <td>{{ dateAndTime(d.created_ts) }}</td> -->

                <td>
                  <div
                    class="btn bg-refunded text-white font-size-12 py-0 px-1"
                    v-if="d.status == 3"
                  >
                    Cancelled
                  </div>
                  <span
                  type="span"
                  class="badge bg-danger text-white font-size-12"
                  v-if="d.status == 4"
                  >Payment Failed</span
                >

                  <div
                    class="btn bg-primary text-white font-size-12 py-0 px-1"
                    v-if="d.status == 1"
                  >
                    Confirmed
                  </div>
                  <div
                    class="btn bg-pending text-white font-size-12 py-0 px-1"
                    v-if="d.status == 2"
                  >
                    Inprogress
                  </div>
                </td>
                <td>{{ d.credit_count }}</td>
                <td>
                  <router-link
                    :to="'/farm-stay/booking/' + d._id"
                    class="btn btn-primary btn-sm"
                    >View</router-link
                  >
                </td>
              </template>
              <template v-else> </template>
            </tr>
          </tbody>
        </table>
        <!-- pagination -->
        <div class="row" v-if="data.length > 0">
          <div class="col-md-12 text-end fw-semibold font-size-12">
            <span class="me-4">Rows per page:</span>
            <div class="d-inline" style="width: 300px">
              <select
                class="border-0 border-bottom p-2"
                v-model="limit"
                @change="getData"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="100">100</option>
              </select>
            </div>
            <span class="mx-5"> {{ this.page }} of {{ this.total_page }} </span>
            <button
              class="font-size-2 border-0 bg-white"
              @click="prevPage()"
              type="button"
              :disabled="isPreviousBtn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-chevron-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
            </button>
            <button
              class="font-size-2 me-5 ms-3 border-0 bg-white"
              @click="nextPage()"
              type="button"
              :disabled="isNextBtn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-chevron-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>Loading ....</div>
  </div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import JsonToCSV from "@/components/JsonToCSV";
import dateUtilities from '@/composables/dateUtilities.js'



export default {
  setup() {
    const { dateAndTime,dateFormat } = dateUtilities()
    return { dateAndTime, dateFormat};
  },
  components: { VueDatePicker, JsonToCSV },
  data() {
    return {
      isLoading: false,
      data: [],
      retreats: [],
      csv_data: [],
      limit: 20,
      page: 1,
      isPreviousBtn: true,
      isNextBtn: true,
      total_page: 1,
      forms: {

        dates: "",
        retreat_id: "",
        query: "",
        bookingStatus:"",
        bookingID:"",
        bookingSource:""
      },
      cofarmer_details: [],
    };
  },
  mounted() {
    this.getRetreats();
    this.getCoFarmerDetails();
    this.getData();
  },
  methods: {
    myChangeEvent() {},
    mySelectEvent() {},
    async getCoFarmerDetails() {
      let coFarmerDetails = await this.$axios.get("/users?type=farmer");
      this.cofarmer_details = [];
      coFarmerDetails.data.data.forEach((cf) => {
        this.cofarmer_details.push({
          id: cf._id,
          text: cf.name,
        });
      });
    },
    getRetreats() {
      this.$axios.get("/retreats").then((res) => {
        //this.retreats = res.data.data;
        res.data.data.forEach((cf) => {
          this.retreats.push({
            id: cf._id,
            text: cf.display_name,
          });
        });
      });
    },
    prevPage() {
      if (this.page > 1) {
        this.page--;
        this.getData();
        this.isPreviousBtn = false;
      } else {
        this.isPreviousBtn = true;
      }
    },
    nextPage() {
      if (this.total_page >= this.page) {
        this.page++;
        this.getData();
        this.isNextBtn = false;
      } else {
        this.isNextBtn = true;
      }
    },
    getData() {
      let form = this.forms;
      let start_date = form.dates ? form.dates[0] : "";
      let end_date = form.dates ? form.dates[1] : "";

      let query = form.query;
      let retreat_id = form.retreat_id;
      this.isLoading = true;

      this.$axios
        .get("/farm-stay/bookings", {
          params: {
            limit: this.limit,
            page: this.page,
            query: query,
            check_in_start: start_date,
            check_in_end: end_date,
            retreat_id: retreat_id,
            bookingStatus: form.bookingStatus,
            bookingID: form.bookingID,
            bookingSource: form.bookingSource,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.isLoading = false;
            this.data = res.data.data.data;
            this.total_page = res.data.data.pages;
            this.csv_data = this.data.map((d) => {
              return {
                "Transaction ID": d.transaction_id,
                Retreat: d._farm_retreat_id.display_name,
                Cottage: d._farm_retreat_cottage_id.cottage_name,
                "Booking date": this.dateAndTime(d.created_ts),
                "Check in": this.dateFormat(d.check_in),
                "Check out": this.dateFormat(d.check_out),
                Days: d.day_counts,
                Nights: d.night_counts,
                "Credit Used": d.credit_count,
                Adults: d.adult_counts,
                Child: d.child_counts,
                Guest: d.guest_counts,
                Pets: d.pets_counts,
                "Amount Paid": d.amount_paid,
                Status: this.getBookingStatusById(d.status),
                "Refunded Amount": d.refunded_amount,
                "Reason for cancellation": d.refund_remarks,
                "Refund Date and Time": d.refund_ts
                  ? this.dateAndTime(d.refund_ts)
                  : "",
                name: d.user_id ? d.user_id.name : "",
                "Mobile number": d.mobile,
                "Email Address": d.email,
              };
            });
            if (this.total_page > this.page) {
              this.isNextBtn = false;
            } else {
              this.isNextBtn = true;
            }
            if (this.page > 1) {
              this.isPreviousBtn = false;
            } else {
              this.isPreviousBtn = true;
            }
          } else {
            this.isLoading = false;
          }
        })
        .catch( (error)  => {
          this.isLoading = false;
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    },

    getBookingStatusById(id) {
      switch (id) {
        case 1:
          return "Confirmed";
          break;
        case 2:
          return "Refund Pending";
          break;
        case 3:
          return "Cancelled";
          break;
        case 4:
          return "Cancelled";
          break;
      }
    },

  },
};
</script>
<style>
.dp__input {
  border-color: black !important;
}
.select2-selection--single {
  border: 1px solid rgba(8, 32, 50, 0.8) !important;
  font-size: 1rem !important;
  height: 2.5em !important;
  border-radius: 5px !important;
}

.select2-selection__arrow {
  top: 6px !important;
}
</style>
