<template>
  <div>
    <div class="row align-items-center mt-4">
      <div class="col-md-12 text-end">
        <div>
          <router-link
            :to="
              '/farm-stay/retreat/' + $route.params.retreat_id + '/edit/photo'
            "
            class="btn bg-primary text-white font-size-1 m-1"
            >Edit Photo</router-link
          >
          <button
            type="button"
            v-if="!isFieldDisabled"
            @click="cancelBtnClicked"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn bg-primary text-white font-size-1"
            @click="toggleFields"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
    </div>

    <div class="media">
      <p class="fw-semibold font-size-2">Cover Photo</p>
      <!-- Section: Images -->
      <section class="">
        <div class="d-flex" v-if="retreat.image">
          <div class="me-2">
            <img :src="retreat.image.url" style="height: 150px; width: 220px" />
          </div>
        </div>
      </section>
      <!-- Section: Images -->
    </div>
    <hr />
    <div class="media">
      <p class="fw-semibold font-size-2">Photos</p>
      <!-- Section: Images -->
      <section class="">
        <div class="d-flex">
          <template v-for="(img, i) in retreat.images" :key="i">
            <div class="me-2" v-if="img.url != retreat.image.url">
              <div
                class="bg-image hover-overlay ripple shadow-1-strong rounded"
              >
                <img :src="img.url" style="height: 150px; width: 220px" />
              </div>
            </div>
          </template>
        </div>
      </section>
      <!-- Section: Images -->
    </div>
    <hr />
    <div class="retreat_information">
      <p class="fw-semibold font-size-2">Retreat information</p>
      <div class="mb-3">
        <label for="" class="fw-semibold mb-2 font-size-1">Farm</label>
        <input
          type="text"
          v-model="retreat.farm_name"
          name=""
          id=""
          class="form-control"
          placeholder=""
          style="width: 400px"
          disabled
        />
      </div>
      <div class="mb-3">
        <label for="" class="fw-semibold mb-2 font-size-1">Display name</label>
        <input
          type="text"
          v-model="retreat.display_name"
          name=""
          id=""
          class="form-control"
          placeholder="Sambhrama"
          style="width: 400px"
          :disabled="isFieldDisabled"
        />
      </div>
      <div class="mb-3">
        <label for="" class="fw-semibold mb-2 font-size-1">Location</label>
        <input
          type="text"
          v-model="retreat.location"
          name=""
          id=""
          class="form-control"
          placeholder="Enter coordinates"
          style="width: 400px"
          :disabled="isFieldDisabled"
        />
      </div>

      <div class="mb-3">
        <label for="" class="fw-semibold mb-2 font-size-1"
          >Google map url</label
        >
        <input
          type="text"
          v-model="retreat.google_map_url"
          name=""
          id=""
          class="form-control"
          placeholder="Enter map url"
          style="width: 400px"
          :disabled="isFieldDisabled"
        />
      </div>
      <div class="mb-3">
        <label for="description" class="fw-semibold mb-2 font-size-1"
          >Description</label
        ><br />
        <textarea
          v-model="retreat.description"
          class=""
          id="description"
          rows="50"
          cols="47"
          maxlength="500"
          :disabled="isFieldDisabled"
          style="height: 150px; width: 400px"
        ></textarea>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-3">
          <p class="fw-semibold font-size-2">Additional information</p>
          <div class="mb-3">
            <label for="" class="fw-semibold mb-2 font-size-1">Amenities</label
            ><br />
            <Multiselect
              mode="tags"
              :close-on-select="true"
              :searchable="true"
              :create-option="true"
              @select="specialAmenitiesChange"
              v-model="retreat.amenities"
              :options="amenities"
              :disabled="isFieldDisabled"
              :canClear="false"
            />
          </div>

          <div class="mb-3">
            <label for="" class="fw-semibold mb-2 font-size-1"
              >Special requirements</label
            ><br />
            <Multiselect
              mode="tags"
              :close-on-select="true"
              :searchable="true"
              :create-option="true"
              v-model="retreat.special_requirement"
              @select="specialRequirementChange"
              :options="special_requirements"
              :disabled="isFieldDisabled"
              :canClear="false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      retreat: {
        _farm_id: {
          name: "",
        },
        special_requirement: [],
        amenities: [],
      },
      isFieldDisabled: true,
      buttonText: "Edit",
      amenities: [],
      special_requirements: [],
    };
  },
  mounted() {
    this.getRetreatDetails();
  },
  methods: {
    specialRequirementChange() {
      this.retreat.special_requirement.forEach((a, i) => {
        if (a.length > 25) {
          this.retreat.special_requirement.splice(i, 1);
          alert("Max Allowed Character length is 25");
          return;
        }
      });
      this.special_requirements = this.retreat.special_requirement;
    },
    specialAmenitiesChange() {
      this.retreat.amenities.forEach((a, i) => {
        if (a.length > 25) {
          this.retreat.amenities.splice(i, 1);
          alert("Max Allowed Character length is 25");
          return;
        }
      });
      this.amenities = this.retreat.amenities;
    },

    cancelBtnClicked() {
      this.isFieldDisabled = true;
      this.buttonText = "Edit";
    },
    toggleFields() {
      if (this.isFieldDisabled) {
        this.isFieldDisabled = false;
        this.buttonText = "Save";
      } else {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "Are you sure want to update the Farm Retreat",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              let retreat_id = this.$route.params.retreat_id;
              this.$axios
                .put("/farm-stay/retreat/" + retreat_id, this.retreat)
                .then((resp) => {
                  if (resp.data.success) {
                    location.reload();
                  }
                });
            }
          });
      }
    },
    getRetreatDetails() {
      let retreat_id = this.$route.params.retreat_id;
      this.$axios.get("/farm-stay/retreat/" + retreat_id).then((resp) => {
        this.retreat = resp.data.data;
        if (this.retreat._farm_id) {
          this.retreat.farm_name = this.retreat._farm_id.name;
        } else {
          this.retreat.farm_name = "";
        }
        this.retreat.special_requirement.forEach((sr) => {
          this.special_requirements.push(sr);
        });
        this.retreat.amenities.forEach((am) => {
          this.amenities.push(am);
        });
      });
    },
  },
};
</script>
<style>
textarea:disabled {
  background-color: #f3f4f6;
}
</style>