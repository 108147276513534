<template>
  <button
    class="bg-black px-2 py-2 rounded-1 border-0 ms-auto"
    @click="downloadFile"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="download">
        <path
          id="Vector"
          d="M5 20.5H19V18.5H5V20.5ZM19 9.5H15V3.5H9V9.5H5L12 16.5L19 9.5Z"
          fill="white"
        />
      </g>
    </svg>
  </button>
</template>
<script>
export default {
  props: ["data"],
  methods: {
    downloadFile() {
      const items = this.data;
      const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
      const header = Object.keys(items[0]);
      const csv = [
        header.join(","), // header row first
        ...items.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(",")
        ),
      ].join("\r\n");

      const now = new Date(); // Current date and time
      const unixTimeInSeconds = Math.floor(now.getTime() / 1000);
      let anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      anchor.target = "_blank";
      anchor.download = "booking_list_" + unixTimeInSeconds + ".csv";
      anchor.click();
    },
  },
};
</script>