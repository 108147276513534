<template>
  <div>
    <hr class="mt-0" />
    <form action="">
      <div class="row">
        <div class="col-md-3">
          <button
            class="border px-4 py-2 rounded-8 w-100 text-start bg-white"
            type="button"
            @click="toggleDiv('retreat', $event)"
            ref="retreatbtn"
          >
            <span class="mb-0 fw-semibold font-size-1">Select Retreat</span>
            <br />
            <span class="font-size-12 text-gray-300">
              <span v-if="!form.retreat_id">Select farm or retreat</span>
              <span v-else>
                {{ form.retreat_id.display_name.slice(0, 20) }}</span
              >
            </span>
          </button>

          <!-- Content for Select Retreat -->
          <div
            class="bookcottagehidediv rounded-2"
            ref="selectRetreat"
            style="display: none"
          >
            <div class="select-retreat" id="select-retreat">
              <ul
                class="border rounded-2 font-size-1 fw-semibold px-3 text-gray-300 shw-2"
                style="width: 300px; height: 150px; overflow: auto"
              >
                <li
                  v-for="(r, i) in retreats"
                  :key="i"
                  :value="r._id"
                  @click="setRetreat(r, i)"
                >
                  {{ r.display_name.slice(0, 20) }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <VueDatePicker
            v-model="form.date"
            :enable-time-picker="false"
            range
            no-today
            @update:model-value="handleDate"
            position="left"
            auto-apply
            min-range="1"
            :highlight="special_dates"
            :min-date="new Date()"
          >
            <template #trigger>
              <button
                class="border px-4 py-2 w-100 rounded-8 text-start bg-white"
                type="button"
              >
                <span class="mb-0 fw-semibold font-size-1"
                  >Check-In - Check-Out</span
                >
                <br />
                <span
                  class="font-size-12 text-gray-300"
                  v-if="selectedDate.length == 0"
                  >Add dates</span
                >
                <span v-for="(d, i) in selectedDate" :key="i">
                  <span v-if="i == 1"> - </span>
                  {{ d }}
                </span>
              </button>
            </template>
          </VueDatePicker>
        </div>
        <div class="col-md-3">
          <button
            class="border px-4 py-2 w-100 rounded-8 text-start bg-white"
            @click="toggleDiv('pax', $event)"
            ref="addguestbtn"
            type="button"
          >
            <span class="mb-0 fw-semibold font-size-1">Who’s coming?</span>
            <br />

            <span class="font-size-12 text-gray-300">
              <span v-if="!showGuestStats">Add guests</span>
              <span v-else>
                <span>{{ form.adults }} Adults</span>
                <span v-if="form.children > 0"
                  >{{ form.children }} Children
                </span>
                <span v-if="form.guests > 0">{{ form.guests }} Guests </span>
                <span v-if="form.pets > 0">{{ form.pets }} Pets</span>
              </span>
            </span>
          </button>
          <!-- Content for pax -->
          <div
            class="bookcottagehidediv rounded-2"
            ref="pax"
            style="display: none"
          >
            <div
              class="who-coming border rounded-2 p-3 shw-2"
              id="pax"
              style="width: 300px"
            >
              <div class="d-flex justify-content-between py-2">
                <p class="mb-0 font-size-1 fw-semibold text-gray-300">Adults</p>
                <div>
                  <button
                    class="bg-gray-100 rounded-circle border wh-30 minus"
                    type="button"
                    @click="form.adults--"
                    :disabled="form.adults == 0"
                  >
                    -
                  </button>
                  <span class="mx-2">{{ form.adults }}</span>
                  <button
                    class="bg-gray-100 rounded-circle border wh-30 plus"
                    @click="form.adults++"
                    type="button"
                  >
                    +
                  </button>
                </div>
              </div>
              <hr class="my-1" />
              <div class="d-flex justify-content-between py-2">
                <p class="mb-0 font-size-1 fw-semibold text-gray-300">
                  Children
                </p>
                <div>
                  <button
                    class="bg-gray-100 rounded-circle border wh-30 minus"
                    type="button"
                    @click="form.children--"
                    :disabled="form.children == 0"
                  >
                    -
                  </button>
                  <span class="mx-2">{{ form.children }}</span>
                  <button
                    class="bg-gray-100 rounded-circle border wh-30 plus"
                    @click="form.children++"
                    type="button"
                  >
                    +
                  </button>
                </div>
              </div>
              <hr class="my-1" />
              <div class="d-flex justify-content-between py-2">
                <p class="mb-0 font-size-1 fw-semibold text-gray-300">Guests</p>
                <div>
                  <button
                    class="bg-gray-100 rounded-circle border wh-30 minus"
                    type="button"
                    @click="form.guests--"
                    :disabled="form.guests == 0"
                  >
                    -
                  </button>
                  <span class="mx-2">{{ form.guests }}</span>
                  <button
                    class="bg-gray-100 rounded-circle border wh-30 plus"
                    @click="form.guests++"
                    type="button"
                  >
                    +
                  </button>
                </div>
              </div>
              <hr class="my-1" />
              <div class="d-flex justify-content-between py-2">
                <p class="mb-0 font-size-1 fw-semibold text-gray-300">Pets</p>
                <div>
                  <button
                    class="bg-gray-100 rounded-circle border wh-30 minus"
                    type="button"
                    @click="form.pets--"
                    :disabled="form.pets == 0"
                  >
                    -
                  </button>
                  <span class="mx-2">{{ form.pets }}</span>
                  <button
                    class="bg-gray-100 rounded-circle border wh-30 plus"
                    @click="form.pets++"
                    type="button"
                  >
                    +
                  </button>
                </div>
              </div>
              <!-- <div class="text-end mt-2">
                                                <button type="button" @click="guestApplyBtn" class="btn btn-sm bg-primary text-white me-2">Cancel</button>
                                                <button type="button" @click="guestApplyBtn" class="btn btn-sm bg-primary text-white">Apply</button>
                                            </div> -->
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <button
            type="button"
            @click="searchBtn"
            class="btn bg-primary text-white mt-2 cottageBtn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
              ></path>
            </svg>
            Search
          </button>
        </div>
      </div>
    </form>
    <div v-if="!isLoading">
      <template v-if="results.length > 0 && searched">
        <table class="table mt-5">
          <thead class="font-size-1">
            <tr>
              <th class="bg-gray-250">Cottage</th>
              <th class="bg-gray-250">Cottages Required</th>
              <th class="bg-gray-250">Cottages Available</th>
              <th class="bg-gray-250">Price per cottage (without Credit)</th>
              <th class="bg-gray-250">Price per cottage (with Credit)</th>
              <th class="bg-gray-250">Actions</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="results.length > 0">
              <tr
                class="text-muted"
                v-for="(result, i) in results"
                :key="i"
              >
                <td>{{ result.name }}</td>
                <td>{{ result.cottage_count }}</td>
                <td>{{ result.available }}</td>
                <td>Rs {{ result.total_without_credit }}</td>
                <td>Rs {{ result.total_with_credit }}</td>
                <td>
                  <div
                    class="btn bg-primary text-white font-size-12"
                    @click="BookNow(result)"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Book now
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="5" class="text-center font-weight-bold">
                  No Result found
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </template>
      <template v-else-if="searched == false">
        <div class="text-center">
          <img src="/assets/img/svg/svg1.svg" alt="" />
          <p>
            Please select the booking options to check the availabily of the
            cottage
          </p>
        </div>
      </template>
      <template v-else-if="searched == true">
        <h5>No Result found</h5>
      </template>
    </div>
    <div v-else>Loading...</div>
  </div>
</template>
<script>
import VueDatePicker from "@vuepic/vue-datepicker";

import moment from "moment";

export default {
  components: { VueDatePicker },
  data() {
    return {
      form: {
        retreat_id: "",
        adults: 0,
        children: 0,
        guests: 0,
        pets: 0,
        date: [],
      },
      isLoading: false,
      selectedDate: [],
      retreats: [],
      showGuestStats: true,
      results: [],
      hideBookingPage: true,
      booking_details: {},
      special_dates: [],
      searched: false,
    };
  },
  mounted() {
    this.getRetreats();
    this.getSpecialDates();
  },
  methods: {
    // holiday-calender
    getSpecialDates() {
      this.$axios.get("/holiday-calender").then((res) => {
        res.data.data.forEach((r) => {
          let date1 = moment(r.date).format("YYYY-MM-DD");
          this.special_dates.push(date1);
        });
      });
    },
    cancelBtn() {
      this.hideBookingPage = true;
    },
    dateTime(value) {
      return moment(value).format("DD-MM-YYYY");
    },

    setRetreat(r, i) {
      this.form.retreat_id = r;
      this.$refs.selectRetreat.style.display = "none";
    },
    getRetreats() {
      this.$axios.get("/retreats").then((res) => {
        console.log(res.data);
        this.retreats = res.data.data;
      });
    },
    handleDate() {
      this.selectedDate = [];
      this.form.date.forEach((d) => {
        d = moment(d).format("DD-MM-YYYY");
        this.selectedDate.push(d);
      });
    },
    guestApplyBtn() {
      this.showGuestStats = true;
    },
    async searchBtn() {
      let check_in = moment(this.form.date[0]);
      let check_out = moment(this.form.date[1]);
      let diff = check_in.diff(check_out, "days");

      if (diff == 0) {
        alert("Check In and Checkout date shouldn't be same");
        return false;
      }
      if (!this.form.retreat_id) {
        alert("Select Retreat");
        return false;
      }
      if (this.form.adults + this.form.children == 0) {
        alert("Select an Adults and Children");
        return false;
      }

      this.closeAllDiv();

      let temp_data = {
        check_in: this.form.date[0],
        check_out: this.form.date[1],
        adults: this.form.adults,
        children: this.form.children,
        guests: this.form.guests,
        pets: this.form.pets,
        retreat_id: this.form.retreat_id._id,
      };
      this.isLoading = true;
      try {
        let r = await this.$axios.get("/farm-stay/search", {
          params: temp_data,
        });
        this.isLoading = false;
        this.searched = true;
        this.results = r.data.data;
      } catch (err) {
        console.log(err);
      }
    },
    async BookNow(d) {
      let data = {
        retreat_id: this.form.retreat_id._id,
        cottage_id: d.id,
        adults: this.form.adults,
        children: this.form.children,
        guests: this.form.guests,
        pets: this.form.pets,
        check_in: this.form.date[0],
        check_out: this.form.date[1],
      };
      console.log(data);
      this.$router.push({ name: "offlineBooking", query: data });
    },
    closeAllDiv() {
      this.$refs.pax.style.display = "none";
      this.$refs.selectRetreat.style.display = "none";
    },
    toggleDiv(div, $event) {
      if (div == "pax") {
        let currentBtn = this.$refs.addguestbtn;
        let current = this.$refs.pax.style.display;
        if (current == "block") {
          this.$refs.pax.style.display = "none";
        } else {
          this.$refs.pax.style.display = "block";
          this.$refs.pax.style.top = `${
            currentBtn.offsetTop + currentBtn.offsetHeight
          }px`;
          this.$refs.pax.style.left = `${currentBtn.offsetLeft}px`;
        }
      } else if (div == "retreat") {
        let currentBtn = this.$refs.retreatbtn;
        let current = this.$refs.selectRetreat.style.display;
        if (current == "block") {
          this.$refs.selectRetreat.style.display = "none";
        } else {
          this.$refs.selectRetreat.style.display = "block";
          this.$refs.selectRetreat.style.top = `${
            currentBtn.offsetTop + currentBtn.offsetHeight
          }px`;
          this.$refs.selectRetreat.style.left = `${currentBtn.offsetLeft}px`;
        }
      }
    },
  },
};
</script>
<style>
.bg-cont {
  min-height: 90vh;
}

.wh-30 {
  width: 30px;
  height: 30px;
}

.cottageBtn {
  padding: 10px 24px;
}

/**.text-center::after {
   content: 'Please select the booking options to check the availabily of the cottage.';
   opacity: .75;
   font-weight: 500;
   display: block;
} */
</style>