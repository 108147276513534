<template>
  <div>
    <div class="row align-items-center mt-4">
      <div class="col-md-12 text-end">
        <div v-if="!isFieldDisabled">
          <button
            type="button"
            class="p-1 rounded-2 bg-gray-200 font-size-1 me-2 px-2"
            @click="toggleBtn"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn bg-primary text-white font-size-1"
            @click="submitCottage"
          >
            Save
          </button>
        </div>
        <div v-else>
          <button
            type="button"
            class="btn bg-primary text-white font-size-1"
            @click="toggleBtn"
          >
            Edit
          </button>
        </div>
      </div>
    </div>
    <div class="cottage_information">
      <p class="fw-semibold font-size-2">Cottage information</p>

      <div class="mb-3">
        <label for="" class="fw-semibold mb-2 font-size-1">Name</label>
        <input
          type="text"
          v-model="cottage.cottage_name"
          :disabled="isFieldDisabled"
          name=""
          id=""
          class="form-control"
          style="width: 400px"
        />
      </div>
      <div class="mb-3">
        <label for="" class="fw-semibold mb-2 font-size-1"
          >No of cottages</label
        >
        <input
          type="number"
          :disabled="isFieldDisabled"
          v-model="cottage.cottage_count"
          name=""
          id=""
          class="form-control"
          style="width: 400px"
        />
      </div>
      <hr />
      <p class="fw-semibold font-size-2">Pricing</p>
      <div class="mb-3">
        <label for="rack_price" class="fw-semibold mb-2 font-size-1"
          >Rack price</label
        >
        <input
          type="number"
          v-model="cottage.rack_price"
          :disabled="isFieldDisabled"
          name=""
          id="rack_price"
          class="form-control"
          placeholder=""
          style="width: 400px"
        />
      </div>
      <div class="mb-3">
        <label for="" class="fw-semibold mb-2 font-size-1"
          >Price on weekdays
          <span class="text-gray-300">(Monday - Friday)</span></label
        >
        <input
          type="number"
          v-model="cottage.weekdays_price"
          :disabled="isFieldDisabled"
          name=""
          id=""
          class="form-control"
          placeholder=""
          style="width: 400px"
        />
      </div>
      <div class="mb-3">
        <label for="" class="fw-semibold mb-2 font-size-1"
          >Price on weekends
          <span class="text-gray-300">(Saturday - Sunday)</span></label
        >
        <input
          type="number"
          v-model="cottage.weekends_price"
          :disabled="isFieldDisabled"
          name=""
          id=""
          class="form-control"
          placeholder=""
          style="width: 400px"
        />
      </div>
      <div class="mb-3">
        <label for="" class="fw-semibold mb-2 font-size-1"
          >Price on special days
          <span class="text-gray-300">(Add from Farm Stay)</span></label
        >
        <input
          type="number"
          v-model="cottage.special_days_price"
          :disabled="isFieldDisabled"
          name=""
          id=""
          class="form-control"
          placeholder=""
          style="width: 400px"
        />
      </div>
      <div class="mb-3 d-flex">
        <div class="me-3">
          <input
            type="checkbox"
            value="true"
            v-model="cottage.guest_allowed"
            :disabled="isFieldDisabled"
            name="guest"
            id="guest"
          />&nbsp;
          <label for="guest" class="fw-semibold mb-2">Guest</label>

          <input
            type="number"
            v-model="cottage.guest_fee"
            :disabled="!cottage.guest_allowed || isFieldDisabled"
            name=""
            id=""
            class="form-control"
            placeholder="Enter the guest fee"
            style="width: 188px"
          />
        </div>
        <div>
          <input
            type="checkbox"
            v-model="cottage.pet_allowed"
            :disabled="isFieldDisabled"
            @change="petAllowedChanged"
            value="true"
            name="pet"
            id="pet"
          />&nbsp;
          <label for="pet" class="fw-semibold mb-2">Pet</label>

          <input
            type="number"
            v-model="cottage.pet_fee"
            :disabled="!cottage.pet_allowed || isFieldDisabled"
            name=""
            id=""
            class="form-control"
            placeholder="Enter the pet fee"
            style="width: 188px"
          />
        </div>
      </div>
      <hr />
      <p class="fw-semibold font-size-2">Availability</p>
      <div class="mb-3">
        <label for="" class="fw-semibold mb-2 font-size-1"
          >Maximum members allowed?</label
        >
        <input
          type="number"
          v-model="cottage.max_member_allowed"
          disabled
          name=""
          id=""
          class="form-control"
          placeholder="4"
          style="width: 400px"
        />
      </div>
      <div class="mb-3">
        <label for="" class="fw-semibold mb-2 font-size-1"
          >Maximum adults allowed?</label
        >
        <input
          type="number"
          v-model="cottage.max_adults_allowed"
          :disabled="isFieldDisabled"
          @change="adultsAllowed"
          name=""
          id=""
          class="form-control"
          placeholder="3"
          style="width: 400px"
        />
      </div>
      <div class="mb-3">
        <label for="" class="fw-semibold mb-2 font-size-1"
          >Maximum children allowed?</label
        >
        <input
          type="number"
          v-model="cottage.max_child_allowed"
          :disabled="isFieldDisabled"
          name=""
          id=""
          class="form-control"
          placeholder="2"
          style="width: 400px"
        />
      </div>
      <div class="mb-3">
        <label for="" class="fw-semibold mb-2 font-size-1"
          >Maximum pets allowed?</label
        >
        <input
          type="number"
          v-model="cottage.max_pets_allowed"
          :disabled="isFieldDisabled"
          name=""
          id=""
          class="form-control"
          placeholder="1"
          style="width: 400px"
        />
      </div>
      <p class="fw-semibold font-size-2">Discounts</p>
      <div class="mb-3">
        <label for="" class="fw-semibold mb-2 font-size-1"
          >Discount with Credits</label
        >
        <input
          type="number"
          v-model="cottage.discount_with_credits"
          :disabled="isFieldDisabled"
          name=""
          id=""
          class="form-control"
          placeholder="4"
          style="width: 400px"
        />
      </div>
      <div class="mb-3">
        <label for="" class="fw-semibold mb-2 font-size-1"
          >Discount without Credits</label
        >
        <input
          type="number"
          v-model="cottage.discount_without_credits"
          :disabled="isFieldDisabled"
          @keyup="DiscountWithoutCreditChanged"
          name=""
          id=""
          class="form-control"
          placeholder="4"
          style="width: 400px"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cottage: {
        _id: "",
        cottage_name: "",
        cottage_count: 0,
        rack_price: 0,
        weekdays_price: 0,
        weekends_price: 0,
        special_days_price: 0,
        guest_fee: 0,
        pet_fee: 0,
        pet_allowed: false,
        guest_allowed: false,
        discount_with_credits: 0,
        discount_without_credits: 0,
        max_member_allowed: 0,
        max_adults_allowed: 0,
        max_child_allowed: 0,
        max_pets_allowed: 0,
        retreat_id: "",
      },
      isFieldDisabled: true,
    };
  },
  mounted() {
    this.getCottageInfo();
  },
  methods: {
    DiscountWithoutCreditChanged() {
      if (
        this.cottage.discount_without_credits >
        this.cottage.discount_with_credits
      ) {
        alert(
          "Discount without credit should be less or equal than with credit discount"
        );
        this.cottage.discount_without_credits =
          this.cottage.discount_with_credits;
      }
    },
    adultsAllowed() {
      this.cottage.max_member_allowed =
        this.cottage.max_adults_allowed + this.cottage.max_child_allowed;
    },
    toggleBtn() {
      this.isFieldDisabled = !this.isFieldDisabled;
    },
    getCottageInfo() {
      let retreat_id = this.$route.params.retreat_id;
      let cottage_id = this.$route.params.cottage_id;

      this.$axios
        .get(
          "/farm-stay/retreat/" +
            retreat_id +
            "/cottage/" +
            cottage_id +
            "/edit"
        )
        .then((res) => {
          this.cottage = res.data.data;
        });
    },
    petAllowedChanged() {
      if (this.cottage.pet_allowed && this.cottage.max_pets_allowed == 0) {
        alert("Max Pet Allowed count should be more than zero if pet allowerd");
        this.cottage.max_pets_allowed = 1;
      }
    },
    submitCottage() {
      console.log(this.cottage);
      let retreat_id = this.$route.params.retreat_id;
      let cottage_id = this.$route.params.cottage_id;
      this.$axios
        .put(
          "/farm-stay/retreat/" +
            retreat_id +
            "/cottage/" +
            cottage_id +
            "/edit",
          this.cottage
        )
        .then((res) => {
          console.log(res);
          if (res.data.success) alert("Successfully Saved");
          this.$router.go(-1);
        });
    },
  },
};
</script>