<template>
  <div>

            <hr class="mt-0" />

            <div class="row">
              <div class="col-md-6 pe-5">
                <VueDatePicker
                  v-model="special_date"
                  :enable-time-picker="false"
                  :format="format"
                  no-today
                  inline
                  auto-apply
                  @update:model-value="handleDate"
                  :min-date="new Date()"
                  :highlight="mark_special_dates"
                />

                <input
                  type="checkbox"
                  name="mark_special_date"
                  @change="markAsSpecialDate()"
                  ref="theCheckbox"
                  class="mt-3"
                />
                Mark as special date
              </div>
              <div class="col-md-6 border-start px-5">
                <p class="fw-semibold font-size-3">Special days</p>
                <ul
                  class="basic-list list-unstyled basic-pad fw-semibold text-gray-300"
                >
                  <li v-for="(sd, i) in formated_mark_special_dates" :key="i">
                    {{ sd }}
                  </li>
                </ul>
              </div>
            </div>
  </div>
</template>
<style>
.dp__flex_display {
  display: block !important;
}

.dp__cell_highlight {
  color: #ed6c30 !important;
  background-color: transparent !important;
}

.dp__today {
  border: none !important;
  border-radius: 50% !important;
  background-color: #449834 !important;
  color: white !important;
}

.dp__inner_nav {
  background-color: #449834 !important;
  border-radius: 6px !important;
  color: #ffff;
}

.dp__inner_nav:hover {
  color: #ffff;
}

.dp__calendar_header_separator {
  display: none !important;
}

.dp__theme_light {
  --dp-primary-color: #449834 !important;
}

.dp__calendar_item {
  text-align: -webkit-center !important;
}
</style>
<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import moment from "moment";

export default {
  components: { VueDatePicker },
  data() {
    return {
      special_date: null,
      mark_special_dates: [],
      formated_mark_special_dates: [],
    };
  },
  computed: {},
  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.$axios.get("/holiday-calender").then((res) => {
        console.log(res.data);
        res.data.data.forEach((r) => {
          let date1 = moment(r.date).format("YYYY-MM-DD");
          let date = moment(r.date).format("dddd, MMMM Do, YYYY");
          this.formated_mark_special_dates.push(date);
          this.mark_special_dates.push(date1);
        });
      });
    },
    create() {},

    handleDate() {
      if (this.$refs.theCheckbox.checked == true) {
        this.$refs.theCheckbox.checked = false;
        let date = moment(this.special_date).format("YYYY-MM-DD");
        var index = this.mark_special_dates.indexOf(date);

        if (index > -1) {
          this.$refs.theCheckbox.checked = true;
        }
      } else {
        let date = moment(this.special_date).format("YYYY-MM-DD");
        var index = this.mark_special_dates.indexOf(date);

        if (index > -1) {
          this.$refs.theCheckbox.checked = true;
        }
      }
      console.log(this.special_date);
    },
    markAsSpecialDate() {
      if (this.$refs.theCheckbox.checked) {
        let date = moment(this.special_date).format("YYYY-MM-DD");
        let date1 = moment(this.special_date).format("dddd, MMMM Do, YYYY");
        this.mark_special_dates.push(date);
        this.formated_mark_special_dates.push(date1);
        this.$axios
          .post("/holiday-calender", {
            date: date,
          })
          .then((res) => {
            console.log(res.data);
          });
      } else {
        let date = moment(this.special_date).format("YYYY-MM-DD");
        let date1 = moment(this.special_date).format("dddd, MMMM Do, YYYY");
        var index = this.mark_special_dates.indexOf(date);
        var index1 = this.formated_mark_special_dates.indexOf(date1);
        if (index > -1) {
          this.mark_special_dates.splice(index, 1);
          console.log("DATE", date);
          this.$axios.delete("/holiday-calender/" + date).then((res) => {
            console.log(res.data);
          });
        }
        if (index1 > -1) {
          this.formated_mark_special_dates.splice(index, 1);
        }
      }
    },
  },
};
</script>

