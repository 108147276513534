<template>
    <div>
        <h1>Login</h1>
    </div>
</template>
<script>
    export default {
        data(){
            return {
                token
            }
        },
        mounted(){
        this.handleToken();
        },
        methods:{
            handleToken(){

            }
        }
    }
</script>