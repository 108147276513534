<template>
  <div>
    <div class="row mt-2 mb-2">
      <div class="col-md-12 text-end mb-4 mt-2">
        <button
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          class="bg-primary px-3 py-2 rounded-1 border-0 text-white mr-2"
        >
          New Activity
        </button>
      </div>
      <div class="col-md-3">
        <Select2
          v-model="forms.activityTypeIds"
          :options="projectsList"
          class="form-select2"
          placeholder="Activity type"
          id="actActType"
          :settings="{
            allowClear: true,
          }"
          @change="activityTypeSelected($event)"
          @select="activityTypeSelected($event)"
        >
        </Select2>
      </div>

      <div class="col-md-3">
        <Select2
          v-model="forms.projectId"
          :options="projectsList"
          class="form-select2"
          placeholder="Select Project"
          id="actProj"
          :settings="{
            allowClear: true,
          }"
          @change="projectSelected($event)"
          @select="projectSelected($event)"
        >
        </Select2>
      </div>
      <div class="col-md-3">
        <Select2
          v-model="forms.ZoneId"
          :options="zones"
          class="form-select2"
          placeholder="Select Zone"
          id="actZone"
          :settings="{
            allowClear: true,
          }"
          ref="zoneSelect"
          @change="ZoneChanged($event)"
          @select="ZoneChanged($event)"
        >
        </Select2>
      </div>
      <div class="col-md-3">
        <Select2
          v-model="forms.PlotId"
          :options="plots"
          class="form-select2"
          placeholder="Select Plot"
          id="actPlot"
          :settings="{
            allowClear: true,
          }"
        >
        </Select2>
      </div>
      <div class="col-md-3 mt-2">
        <VueDatePicker
          v-model="forms.dates"
          :enable-time-picker="false"
          range
          placeholder="Date Range"
          position="left"
          auto-apply
        ></VueDatePicker>
      </div>
      <div class="col-md-3 mt-2">
        <button
          class="bg-primary px-3 py-2 rounded-1 border-0 text-white mr-2"
          @click="getData"
        >
          Search
        </button>
        <button
          class="bg-primary-inverse px-3 py-2 rounded-1 text-muted"
          @click="resetFormData"
        >
          <i class="fa fa-repeat fa-rotate-left"></i>
        </button>
      </div>
    </div>
    <div class="mt-4">
      <table class="table">
        <thead>
          <tr>
            <th>Activity Type</th>
            <th>Description</th>
            <th>Progress</th>
            <th>Start date</th>
            <th>End date</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <img src="" alt="" />
              Plantation
            </td>
            <td>Irrigation to be done on the plot h112</td>
            <td>20%</td>
            <td>02-05-2024</td>
            <td>02-05-2024</td>
            <td>
              <div class="btn bg-refunded text-white font-size-12 py-0 px-1">
                Completed
              </div>
            </td>
            <td>
              <div class="dropdown">
                <button
                  class="btn btn-link"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="fa fa-ellipsis-h"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                  <li>
                    <button class="dropdown-item" 
                    data-bs-toggle="modal"
                    data-bs-target="#activityViewModal"
                    type="button">View</button>
                  </li>
                  <li>
                    <button class="dropdown-item" type="button" 
                    data-bs-toggle="modal"
                    data-bs-target="#activityEditModal">Edit</button>
                  </li>
                  <li>
                    <button class="dropdown-item" type="button">Delete</button>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <activityCreate></activityCreate>
    <activityEdit></activityEdit>
    <activityView></activityView>
  </div>
</template>
<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import activityCreate from '@/components/activity/create.vue'
import activityEdit from '@/components/activity/edit.vue'
import activityView from '@/components/activity/view.vue'

export default {
  components: {
    VueDatePicker,
    activityCreate,
    activityEdit,
    activityView
  },
  destroy(){
    const selectElements = document.querySelectorAll('.form-select2');
    console.log(selectElements);
  // Loop through each select element
  selectElements.forEach(element => {
    // Check if select2 plugin is initialized for the current select element
    if (element.select2) {
      // If select2 plugin is initialized, destroy it
      $(element).select2('destroy');
    }
  });
  },

  data() {
    return {
      activityTypeList: [],
      projectsList: [],
      zones:[],
      plots:[],
      farms:[],
      forms: {
              activityTypeIds: "",
              projectId: "",
              ZoneId: "",
              PlotId: "",
          }
    };
  },
  mounted(){
  },
  methods: {
    activityTypeSelected() {},
    projectSelected() {},
    ZoneChanged() {},
  },
};
</script>
