<template>
  <div>
    
          <div class="my-3 d-flex gap-2 align-items-center">
            <Select2
              v-model="forms.ProjectId"
              :options="projects"
              class="width-25 form-select2"
              placeholder="Select Project"
              id="mySelect1"
              :settings="{
                allowClear: true,
              }"
              @change="ProjectSelected($event)"
              @select="ProjectSelected($event)"
            >
            </Select2>
            <Select2
              v-model="forms.ZoneId"
              :options="zones"
              class="width-25 form-select2"
              placeholder="Select Zone"
              id="mySelect2"
              :settings="{
                allowClear: true,
              }"
              ref="zoneSelect"
              @change="ZoneChanged($event)"
              @select="ZoneChanged($event)"
            >
            </Select2>
            <Select2
              v-model="forms.PlotId"
              :options="plots"
              class="width-25 form-select2"
              placeholder="Select Plot"
              id="mySelect3"
              :settings="{
                allowClear: true,
              }"
            >
            </Select2>

            <button
            class="bg-primary px-3 py-2 rounded-1 border-0 text-white mr-2"
              @click="getData"
            >
              Search
            </button>

            <dropDown
              class="mr-2"
              :btnName="'Download'"
              :menuItems="dropDownItems"
              @menuItemClick="handleMenuItemClick"
            ></dropDown>
          </div>
        
          <div v-if="!isLoading && isDataLoaded">
            <table class="table text-center" id="temp-target">
              <thead class="font-size-12">
                <tr>
                  <th>Plant Name</th>
                  <th>Plantation date</th>
                  <th>Age in years</th>
                  <th>Count</th>
                </tr>
              </thead>

              <tbody>
                <template v-if="data.plantations.length > 0">
                    <tr
                    class="font-size-1 fw-semibold"
                    v-for="(d, i) in data.plantations"
                    :key="i"
                  >
                    <template v-if="d">
                      <td>{{ d.name }}</td>
                      <td>{{ d.date }}</td>
                      <td>{{ d.age }}</td>
                      <td>{{ d.count }}</td>
                    </template>
                    
                  </tr>
                </template>
                <template v-else> 
                    <td colspan="4" class="text-center font-weight-bold">No Result Found</td>
                  </template>
              </tbody>
            </table>
        
          </div>
          <div v-else>
             <span v-if="isLoading">{{  LoadingMsg }}</span>
             <div class="text-center mt-4" v-else>
               <img src="@/assets/reports/forest.png" alt="" srcset="">
               <p class="font-weight-bold">Filter your search for the most relevant results</p>
             </div>
          </div>
  </div>
</template>
  
  <script>
import VueDatePicker from "@vuepic/vue-datepicker";
import JsonToCSV from "@/components/JsonToCSV";
import dropDown from "@/components/DropDown.vue";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import moment from "moment";

export default {
  components: { VueDatePicker, JsonToCSV,dropDown },
  
  data() {
    return {
      LoadingMsg: 'Loading ...',
      isLoading: false,
      projects: [],
      zones: [],
      plots: [],
      data: [],
      csv_data: [],
      plotsNames:"",
      zoneNames:"",
      projectName:"",
      forms: {
        ProjectId: "",
        ZoneId: "",
        PlotId: "",
      },
      dropDownItems: [
        { value: "csv", displayName: "Download as CSV" },
        { value: "pdf", displayName: "Download as PDF" },
      ],
    };
  },
  mounted() {
    this.getProjects();
  },
  methods: {
    getData() {
      let form = this.forms;
      let ProjectId = form.ProjectId;
      let ZoneId = form.ZoneId;
      let PlotId = form.PlotId;

      if (
        ProjectId == "" ||
        ZoneId == "" 
      ) {
        alert("Please fill all the paramters");
        return;
      }

      if(ZoneId.includes(-1)){
        ZoneId = this.zones.map(z =>  { 
          if (z.id != -1 ) {  return z.id  } 
        });
        ZoneId= ZoneId.filter(z => (z != undefined))
      }

      this.isLoading = true;

      this.$axios
        .post("/reports/plantations", {
            ProjectId: ProjectId,
            ZoneId:ZoneId ,
            PlotId: PlotId
          })
        .then((res) => {
          if (res.data.success) {
            this.isLoading = false;
            this.isDataLoaded = true;
            this.data = res.data.data;
            this.plotsNames = this.data.plotsNames;
            this.zoneNames = this.data.zoneNames;
            this.projectName = this.data.projectNames;
            this.csv_data = this.data.plantations.map((d) => {
              return {
                "Project Name": this.projectName,
                 Name: d.name,
                "Plantation Date": d.date,
                "Age": d.age,
                "Count": d.count,
              };
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          this.LoadingMsg = error.message;
          console.log(error.config);
        });
    },
    handleMenuItemClick(item) {
      if (item.value == "csv") {
        this.downloadCSVFile();
      }
      if (item.value == "pdf") {
        this.downloadPDFFile();
      }
    },
    downloadCSVFile() {
      const items = this.csv_data;
      const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
      const header = Object.keys(items[0]);
      const csv = [
        header.join(","), // header row first
        ...items.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(",")
        ),
      ].join("\r\n");

      const now = new Date(); // Current date and time
      const unixTimeInSeconds = Math.floor(now.getTime() / 1000);
      let anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      anchor.target = "_blank";
      anchor.download = "plantation_report_" + unixTimeInSeconds + ".csv";
      anchor.click();
    },
    downloadPDFFile() {
      var img = new Image()
      img.src = require('@/assets/hosa.png');
      const plotsNames = this.plotsNames.join(',');
      const zoneNames = this.zoneNames.join(',');
      const projectName = this.projectName;
      
      var doc = new jsPDF();
   
      doc.addImage(img, 'PNG', 13, 4, 50, 14, { align: 'left' })
      doc.setFontSize(16);
      doc.text("Plantation Report", 160, 10,{ align: 'left' });
      doc.setFontSize(10);
      doc.text(`Project: ${projectName}`, 13, 30);
      var zoneArr = doc.splitTextToSize(`Zone: ${zoneNames}`, 180)
      let plotStringY = 35 + (zoneArr.length * 5);
      doc.text(zoneArr, 13, 35);
      var plotArr = doc.splitTextToSize(`Plot: ${plotsNames}`, 180)
      let suffix = 0;
      let tabletStringY = suffix + plotStringY + (plotArr.length * 4);
      doc.text(plotArr, 13, plotStringY);
      doc.autoTable({ html: '#temp-target',
      startY:tabletStringY,
      theme: 'grid',
      showHead:'firstPage'
     })
      doc.save(`plantation_report_${moment().format('DDMMYY')}.pdf`)
    },

    getProjects() {
      this.$axios.get("/projects").then((res) => {
        res.data.data.forEach((cf) => {
          this.projects.push({
            id: cf._id,
            text: cf.name,
          });
        });
      });
    },
    ProjectSelected({ id, text }) {
      let project_id = this.forms.ProjectId;
      this.zones = [];
      this.plots = [];
      this.forms.ZoneId = this.forms.PlotId = "";
      this.getZoneBasedOnProjectID(project_id);
    },
    getPlotBasedOnZoneID(zone_id) {
      let projectID = this.forms.ProjectId;
      this.plots = [];
      if(zone_id.includes(-1)){
        zone_id= this.zones.filter(z => (z.id != -1))
        zone_id= zone_id.map(z => z.id);
      }
      
      this.$axios
        .get("/plots", {
          params: {
            zoneId: zone_id,
            projectID: projectID,
          },
        })
        .then((res) => {
          
          if (res.data.data.length > 0) {
            
            res.data.data.forEach((cf) => {
              this.plots.push({
                id: cf._id,
                text: cf.code,
              });
            });
          }
        });
    },
    getZoneBasedOnProjectID(project_id) {
      this.zones = [];
      this.$axios.get("/zones/" + project_id).then((res) => {
      
        if (res.data.data.length > 0) {
          this.zones.push({
            id: '-1',
            text: 'All'
          })
          res.data.data.forEach((cf) => {
            this.zones.push({
              id: cf._id,
              text: cf.code,
            });
          });
        }
      });
    },
    ZoneChanged() {
      this.forms.PlotId = "";
      this.getPlotBasedOnZoneID(this.forms.ZoneId);
    },
  },
};
</script>
  <style scoped>
.dp__input {
  border-color: black !important;
}
.select2-selection--single {
  border: 1px solid rgba(255, 255, 255, 0.8) !important;
  font-size: 1rem !important;
  height: 2.5em !important;
  border-radius: 5px !important;
}

.select2-selection__arrow {
  top: 6px !important;
}

td {
  white-space: nowrap;
}
</style>
  