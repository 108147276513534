<template>
    <ul class="list-group list-group-horizontal mt-3 font-size-1 border-bottom fw-semibold sticky-heading">
        <li class="list-group-item border-0 pb-3" :class="{ 'active-top': $route.name === 'ReportActivities' }">
            <router-link to="/reports/activities">Activities</router-link>
        </li>
        <li class="list-group-item border-0 pb-3" :class="{ 'active-top': $route.name === 'ReportPlantation' }">
            <router-link to="/reports/plantation">Plantation</router-link>
        </li>
       
    </ul>
</template>
<style>
.sticky-heading {
    background-color: #ffffff;
    position: sticky;
}
</style>