import { createStore } from 'vuex'

export default createStore({
  state: {
    token: ''
  },
  getters: {
    getToken(state){
      return state.token;
     },
     getAuthToken(state){
      return state.token;
     }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
