<template>
  <div>
    <main>
      <div v-if="step < 4">
        <section>
          <div class="container mt-5">
            <div class="d-flex justify-content-between">
              <div class="">
                <span :class="{ avatar: step >= 1 }">1</span>
                <span class="fw-medium ms-2">Retreat information</span>
              </div>
              <div style="width: 300px">
                <hr />
              </div>
              <div class="">
                <span :class="{ avatar: step >= 2, 'avatar-disbled': step < 2 }"
                  >2</span
                >
                <span class="fw-medium ms-2">Additional information</span>
              </div>
              <div style="width: 300px">
                <hr />
              </div>
              <div class="">
                <span
                  :class="{ avatar: step == 3, 'avatar-disbled': step != 3 }"
                  >3</span
                >

                <span class="fw-medium ms-2">Media</span>
              </div>
            </div>
          </div>
        </section>
        <hr class="container" />
        <section v-if="step == 1">
          <div class="container mb-5">
            <div class="row text-center mt-5 mb-4">
              <div class="col-md-12">
                <h4 class="fw-semibold">Retreat Information</h4>
              </div>
            </div>
            <div class="container2 mb-10">
              <div class="centered">
                <div class="section-form">
                  <div class="">
                    <label for="" class="fw-semibold mb-2"
                      >Choose the farm</label
                    >
                    <select
                      v-model="form.farm_id"
                      class="form-select mb-3"
                      aria-label=".form-select-lg example"
                    >
                      <option value="">Choose the farm</option>
                      <option
                        :value="project._id"
                        v-for="(project, i) in projects"
                        :key="i"
                      >
                        {{ project.name }}
                      </option>
                    </select>
                  </div>
                  <div class="mb-3">
                    <label for="" class="fw-semibold mb-2">Display name</label>
                    <input
                      type="text"
                      v-model="form.display_name"
                      maxlength="150"
                      class="form-control"
                      placeholder="Enter the name"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="" class="fw-semibold mb-2">Location</label>
                    <input
                      type="text"
                      v-model="form.location"
                      maxlength="150"
                      class="form-control"
                      placeholder="Enter the name"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="" class="fw-semibold mb-2"
                      >Google Map URL</label
                    >
                    <input
                      type="text"
                      v-model="form.google_map_url"
                      maxlength="150"
                      class="form-control"
                      placeholder="Enter the name"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="" class="fw-semibold mb-2">Description</label>
                    <textarea
                      v-model="form.description"
                      class="form-control"
                      placeholder="Max 500 characters"
                      id="floatingTextarea"
                      maxlength="500"
                      style="height: 150px"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section v-if="step == 2">
          <div class="container">
            <div class="row text-center mt-5 mb-4">
              <div class="col-md-12">
                <h4 class="fw-semibold">Additional Information</h4>
              </div>
            </div>
            <div class="container2 mb-10">
              <div class="centered">
                <div class="section-form">
                  <div class="mb-4">
                    <label for="" class="fw-semibold mb-2">Amenities</label>

                    <Multiselect
                      mode="tags"
                      :close-on-select="true"
                      :searchable="true"
                      :breakTags="true"
                      @select="specialAmenitiesChange"
                      :create-option="true"
                      v-model="form.amenities"
                      :canClear="false"
                      :options="amenities"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="" class="fw-semibold mb-2"
                      >Special requirements</label
                    >
                    <Multiselect
                      mode="tags"
                      :close-on-select="true"
                      :searchable="true"
                      :create-option="true"
                      v-model="form.special_requirement"
                      @select="specialRequirementChange"
                      :canClear="false"
                      :options="special_requirements"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section v-if="step == 3">
          <div class="container mb-10">
            <div>
              <div class="row text-center mt-5 mb-4">
                <div class="col-md-12">
                  <h4 class="fw-semibold">Upload Photos</h4>
                </div>
              </div>
              <div class="section-form">
                <div class="">
                  <div
                    class="upload-area__drop-zoon drop-zoon"
                    v-bind="getRootProps()"
                  >
                    <input
                      id="fileInput"
                      class="drop-zoon__file-input"
                      accept="image/*"
                      v-bind="getInputProps()"
                    />
                    <span class="drop-zoon__icon">
                      <i class="fa fa-file-image-o" aria-hidden="true"></i>
                    </span>
                    <p v-if="isDragActive">Drop the files here ...</p>
                    <p v-else>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  </div>
                </div>
                <div></div>
              </div>
              <div class="section-form2">
                <div class="row">
                  <div
                    class="col-md-6 mb-2"
                    v-for="(image, i) in form.images"
                    :key="i"
                  >
                    <div
                      class="bg-image hover-overlay ripple shadow-1-strong rounded position-relative"
                      style="width: 300px; height: 200px"
                    >
                      <img :src="image.location" class="w-100 h-100 rounded" />
                      <button
                        class="upload_btn border-0 rounded-circle"
                        @click="toggle[i] = !toggle[i]"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-three-dots-vertical"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                          />
                        </svg>
                      </button>
                      <ul
                        class="list-unstyled font-size-1 w-50 p-1 rounded upload_li"
                        v-show="toggle[i]"
                      >
                        <li
                          class="border-bottom p-1"
                          @click="MarkAsCoverImage(i)"
                        >
                          Make cover photo
                        </li>
                        <li class="p-1" @click="deleteImage(i)">Delete</li>
                      </ul>
                      <span
                        class="badge text-black upload_span"
                        v-if="image.isCoverImage"
                        >Cover photo</span
                      >
                    </div>
                  </div>

                  <!-- <div class="col-md-2" v-for="(image, i) in form.images" :key="i">
                                        <img :src="image.url" width="200" height="200">
                                        <div class="d-grid gap-2">
                                            <button href="#" class="btn btn-sm " :class="{
                                                'btn-primary': !image.isCoverImage,
                                                'btn-success': image.isCoverImage
                                            }" @click="MarkAsCoverImage(i)">
                                                <i class="fa fa-star" aria-hidden="true"
                                                    v-if="image.isCoverImage == true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true" v-else></i> Cover image
                                            </button>
                                        </div>
                                    </div> -->
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <div class="fixed-bottom bg-white">
            <hr class="mt-0" />
            <div class="row">
              <div class="col-md-6 mb-3 ps-5">
                <a
                  type="button"
                  class="btn bg-gray fw-light px-4 border me-4"
                  v-if="step == 1"
                  @click="$router.go(-1)"
                  >Close</a
                >
                <button
                  type="button"
                  v-if="step != 1"
                  @click="BackStep()"
                  class="btn bg-gray fw-light ms-5 px-4 border"
                >
                  Back
                </button>
              </div>
              <div class="col-md-6 text-end mb-3 pe-5">
                <button
                  type="button"
                  v-if="step < 3"
                  @click="nextStep()"
                  :disabled="enablenextstepbutton"
                  class="btn bg-primary fw-light text-white me-5 px-4"
                >
                  Next
                </button>
                <button
                  type="button"
                  v-if="step == 3"
                  @click="saveButton()"
                  :disabled="enablenextstepbutton"
                  class="btn bg-primary fw-light text-white me-5 px-4"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div v-if="step == 4">
        <main class="div-center">
          <section>
            <div class="container">
              <div class="text-center">
                <img src="/assets/img/icons/done.png" alt="" />
              </div>

              <div class="row text-center mt-4 mb-3">
                <div class="col-md-12">
                  <h2 class="fw-semibold">Retreat is added</h2>
                </div>
              </div>
              <div class="row text-center mb-4">
                <div class="col-md-12">
                  <router-link
                    :to="'/farm-stay/retreat/' + id + '/cottages/create'"
                    class="fw-light btn avatar btn-l"
                  >
                    Add Cottages
                  </router-link>
                </div>
              </div>
              <div class="row text-center mb-4">
                <div class="col-md-12">
                  <router-link
                    to="/farm-stay/retreat"
                    class="fw-light btn text-primary btn-l border-1"
                  >
                    Go to Home
                  </router-link>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </main>
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import { useDropzone } from "vue3-dropzone";

export default {
  components: {
    Multiselect,
  },
  setup() {
    const url = "/upload"; //
    const saveFiles = (files, vm) => {
      const formData = new FormData(); // pass data as a form
      for (var x = 0; x < files.length; x++) {
        formData.append("images", files[x]);
      }
      vm.$axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.info(response.data);
          if (response.data.success) {
            response.data.data.forEach((e) => {
              vm.form.images.push(e);
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };
    function onDrop(acceptFiles, rejectReasons) {
      console.log(acceptFiles);
      console.log(rejectReasons);

      let file_count = window.vm.form.images.length + acceptFiles.length;
      if (file_count > 5) {
        alert("5 Max file can be uploaded");
      } else if (rejectReasons.length == 0) saveFiles(acceptFiles, window.vm);
      else alert("5 Max file can be uploaded");
    }

    const { getRootProps, getInputProps, inputRef, ...rest } = useDropzone({
      onDrop,
      multiple: true,
      maxFiles: 5,
      noClick: false,
    });

    return {
      getRootProps,
      getInputProps,
      inputRef,
      ...rest,
    };
  },
  data() {
    return {
      id: 0,
      step: 1,
      projects: [],
      amenities: [],
      special_requirements: [],
      resp: "",
      form: {
        farm_id: "",
        display_name: "",
        description: "",
        amenities: [],
        special_requirement: [],
        images: [],
      },
      toggle: [],
    };
  },
  mounted() {
    window.vm = this;
    this.getProjects();
  },
  computed: {
    enablenextstepbutton() {
      let currentStep = this.step;
      if (currentStep == 1) {
        if (
          this.form.display_name &&
          this.form.description &&
          this.form.google_map_url &&
          this.form.location
        ) {
          return false;
        } else {
          return true;
        }
      } else if (currentStep == 2) {
        if (
          this.form.amenities.length > 0 &&
          this.form.special_requirement.length > 0
        ) {
          return false;
        } else {
          return true;
        }
      } else if (currentStep == 3) {
        if (this.form.images.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    },
  },
  methods: {
    specialRequirementChange() {
      this.form.special_requirement.forEach((a, i) => {
        if (a.length > 25) {
          this.form.special_requirement.splice(i, 1);
          alert("Max Allowed Character length is 25");
          return;
        }
      });
      this.special_requirements = this.form.special_requirement;
    },

    specialAmenitiesChange() {
      this.form.amenities.forEach((a, i) => {
        if (a.length > 25) {
          this.form.amenities.splice(i, 1);
          alert("Max Allowed Character length is 25");
          return;
        }
      });
      this.amenities = this.form.amenities;
    },
    deleteImage(i) {
      if (confirm("Are you sure you want to delete the photo ?"))
        this.form.images.splice(i, 1);
      else console.log("cancelled");
    },
    MarkAsCoverImage(i) {
      this.form.images.forEach((img, index) => {
        if (index == i) {
          img.isCoverImage = true;
        } else {
          img.isCoverImage = false;
        }
      });
      this.toggle[i] = false;
    },
    getProjects() {
      this.$axios.get("/farms").then((resp) => {
        console.log(resp.data);
        this.projects = resp.data.data;
        console.log(this.projects);
      });
    },
    nextStep() {
      this.step++;
    },
    BackStep() {
      this.step--;
    },
    saveButton() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure want to save the Farm Retreat",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios.post("/farm-stay/retreat", this.form).then((res) => {
              console.log(res.data);
              if (res.data.success) {
                this.step = 4;
                this.id = res.data.data._id;
                this.resp = res.data.data;
              }
            });
          }
        });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.multiselect-tag {
  background-color: #449834 !important;
}

#imagePreview li {
  display: inline;
  list-style-type: none;
  float: left;
}
.mb-10 {
  margin-bottom: 10rem;
}
.multiselect-tags {
  overflow-x: clip;
}
.upload_btn {
  position: absolute;
  top: 8px;
  right: 10px;
  background-color: #f2f2f2;
}
.upload_li {
  position: absolute;
  top: 35px;
  right: 10px;
  background-color: #f2f2f2;
  cursor: pointer;
}
.upload_span {
  position: absolute;
  top: 8px;
  left: 5px;
  background-color: #f2f2f2;
}
.section-form2 {
  position: relative;
  left: 50%;
  transform: translate(-50%, -0%);
  width: 50%;
  margin-bottom: 1rem;
}
</style>