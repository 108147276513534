import { createRouter, createWebHistory } from "vue-router";
import FarmStayView from "../views/retreat/List.vue";
import FarmStayCreateView from "../views/retreat/Create";
import FarmStayCottageListView from "../views/retreat/cottages/List";
import FarmStayEditView from "../views/retreat/Edit";
import FarmStayCottageCreateView from "../views/retreat/cottages/Create";
import FarmStayCottageEditView from "../views/retreat/cottages/Edit";
import FarmStayCalenderView from "../views/retreat/Calender";
import FarmStayBookingListView from "../views/retreat/booking/List";
import FarmStayEditPhotoView from "../views/retreat/EditPhoto.vue";
import FarmStayBookingView from "../views/retreat/booking/View";
import FarmStayRetreatBookListView from "../views/retreat/book/List";
import FarmStayRetreatBookingCreate from "../views/retreat/booking/Create.vue";
import LoginCallBack from "../views/LoginCallBack";
import unauthorized from "../views/Unauthorized";
import store from "../store/index";
import ReportActivitiesView from "../views/reports/ReportActivitiesView";
import ReportPlantationView from "../views/reports/ReportPlantationView";

import AppLayout from "../views/layouts/app.layouts.vue";

import ActivitiesList from "../views/activities/ActivityList.vue";

const routes = [
  {
    path: "/unauthorized",
    name: "unauthorized",
    component: unauthorized,
    meta: {
      title: "Unauthorized",
    },
  },
  {
    path: "/login/callback",
    name: "LoginCallBack",
    component: LoginCallBack,
    meta: {
      title: "Login Callback",
    },
  },
  {
    path: "/farm-stay",
    name: "FarmStayLayout",
    component: AppLayout,
    children: [
      {
        path: "retreat/bookings",
        name: "FarmStayBookingList",
        component: FarmStayBookingListView,
        meta: { requiresAuth: true, title: "Booking List", name: "FarmStayModule", showNavbar:true},
      },
      {
        path: "retreat/book",
        name: "BookRetreat",
        component: FarmStayRetreatBookListView,
        meta: {
          requiresAuth: true,
          title: "Booking Search",
          name: "FarmStayModule",
          showNavbar:true
        },
      },
      {
        path: "calender",
        name: "FarmStayCalender",
        component: FarmStayCalenderView,
        meta: { requiresAuth: true, title: "Special Days", name: "FarmStayModule",showNavbar:true },
      },
      {
        path: "retreat",
        name: "FarmStayList",
        component: FarmStayView,
        meta: { requiresAuth: true, title: "Retreats", name: "FarmStayModule",showNavbar:true },
      },
      {
        path: "booking/:booking_id",
        name: "FarmStayBookingView",
        component: FarmStayBookingView,
        meta: {
          requiresAuth: true,
          title: "Booking Details",
          name: "FarmStayModule",
          showNavbar:true
        },
      },
      {
        path: "retreat/:retreat_id/cottages",
        name: "FarmStayCottageList",
        component: FarmStayCottageListView,
        meta: {
          requiresAuth: true,
          title: "Retreate Cottage",
          name: "FarmStayModule",
          showNavbar:true
        },
      },
      {
        path: "retreat/:retreat_id/edit",
        name: "FarmStayEdit",
        component: FarmStayEditView,
        meta: {
          requiresAuth: true,
          title: "Retreate Cottage Edit",
          name: "FarmStayModule",
          showNavbar:true
        },
      },
      {
        path: "retreat/:retreat_id/cottages/:cottage_id/edit",
        name: "FarmStayCottageEdit",
        component: FarmStayCottageEditView,
        meta: {
          requiresAuth: true,
          title: "Retreate Cottage Edit",
          name: "FarmStayModule",
          showNavbar:true
        },
      },
    ],
  },
  /*
     Retreat create Page
   */
  {
    path: "/farm-stay/retreat/create",
    name: "FarmStayCreate",
    component: FarmStayCreateView,
    meta: { requiresAuth: true, title: "Booking Create", name: "FarmStayModule" },
  },
  /*
     Offline Booking Page
   */
  {
    path: "/farm-stay/retreat/offline-booking/booking",
    name: "offlineBooking",
    component: FarmStayRetreatBookingCreate,
    meta: { requiresAuth: true, title: "Booking", name: "FarmStayModule" },
  },
  /*
     Cottage Create Page
   */
  {
    path: "/farm-stay/retreat/:retreat_id/cottages/create",
    name: "FarmStayCottageCreate",
    component: FarmStayCottageCreateView,
    meta: {
      requiresAuth: true,
      title: "Retreate Cottage Create",
      name: "FarmStayModule",
    },
  },
  /*
     Retreat Edit Photo
   */
  {
    path: "/farm-stay/retreat/:retreat_id/edit/photo",
    name: "FarmStayEditPhoto",
    component: FarmStayEditPhotoView,
    meta: {
      requiresAuth: true,
      title: "Retreate Cottage Edit Photos",
      name: "FarmStayModule",
    },
  },
  {
    path: "/reports",
    name: "ReportActivitiesLayout",
    component: AppLayout,
    children: [
      {
        path: "activities",
        name: "ReportActivities",
        component: ReportActivitiesView,
        meta: {
          requiresAuth: true,
          title: "Activities Report",
          name: "ReportsModule",
          showNavbar:true,
        },
      },
      {
        path: "plantation",
        name: "ReportPlantation",
        component: ReportPlantationView,
        meta: {
          requiresAuth: true,
          title: "Activities Report",
          name: "ReportsModule",
          showNavbar:true,
        },
      },
    ],
  },
  {
    path: '/activities',
    name: 'ActivitiesList',
    component: AppLayout,
    children:[
       {
         path: '',
         name: 'ActivitiesList',
         component: ActivitiesList,
         meta: {
          requiresAuth: true,
          title: "Activities",
          name: "ActivitiesModule",
          showNavbar:false,
         }
       }
    ]
  },
  { path: "/:pathMatch(.*)*", redirect: "/farm-stay/retreat/bookings" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  const title = to.meta.title;
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = title;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.query.token) {
      let queryaccessToken = to.query.token;
      store.state.token = queryaccessToken;
      localStorage.setItem("token", queryaccessToken);
      to.query.token = "";
    }
    let accessToken = localStorage.getItem("token");

    if (accessToken) {
      return;
    }
    return "/unauthorized";
  } else {
    return;
  }
});

export default router;
