<template>
   <div class="dropdown" @click="toggleDropdown">
    <button class="bg-primary-inverse px-3 py-2 rounded-1 border-0">{{ btnName }}</button>
    <div v-if="showDropdown" class="dropdown-content">
      <a href="#" v-for="(item,i) in menuItems" :key="i" 
      @click="handleItemClick(item)"
       > {{  item.displayName }}</a>
    </div>
  </div>
</template>
<script>
    export default {
        props:[
           'btnName',
           'menuItems' 
        ],
        data(){
            return {
                showDropdown : false
            }
        },
        methods: {
            toggleDropdown() {
                this.showDropdown = !this.showDropdown;
            },
            handleItemClick(item) {
                this.$emit('menuItemClick', item);
                this.toggleDropdown(); // Close dropdown after item click (optional)
            }
        }
    }
</script>
<style>
/* Dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown button */
.bg-primary-inverse {
    border : 1px solid #449834 !important;
    color: #449834;
    font-weight: 500;
    background-color: #fff;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
    font-size: 14px;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

</style>