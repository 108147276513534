<template>
  <main>
    <form @submit.prevent="bookingFormSubmit">
      <section class="container mt-4 mb-15">
        <h3 class="text-center">Book a cottage</h3>

        <div class="row mt-5">
          <div class="col-md-6 border-end">
            <div class="w-75 font-size-1">
              <div class="mb-3">
                <label for="" class="fw-semibold mb-2">Co-farmer</label>
                <Select2
                  v-model="form.co_farmer_id"
                  :options="cofarmer_details"
                  id="mySelect"
                  @select="selectedCofarmer($event)"
                  class=""
                  placeholder="Select member"
                >
                </Select2>
              </div>
              <div class="mb-3">
                <label for="" class="fw-semibold mb-2">Mobile number</label>
                <input
                  type="text"
                  v-model="form.mobile_number"
                  name=""
                  id=""
                  class="form-control"
                  placeholder="Must be 10digits"
                />
              </div>
              <div class="mb-3">
                <label for="" class="fw-semibold mb-2">Email address</label>
                <input
                  type="email"
                  v-model="form.email"
                  name=""
                  id=""
                  class="form-control"
                  placeholder="john@example.com"
                />
              </div>
              <div class="mb-3">
                <label for="" class="fw-semibold mb-2">Amount paid</label>
                <input
                  type="number"
                  v-model="form.amount_paid"
                  name=""
                  id=""
                  class="form-control"
                  placeholder="Enter the amount"
                />
              </div>
              <div class="mb-3">
                <label for="" class="fw-semibold mb-2">Transaction Id</label>
                <input
                  type="text"
                  v-model="form.transaction_id"
                  name=""
                  id=""
                  class="form-control"
                  placeholder="Enter Transaction id"
                />
              </div>
              <div class="mb-3">
                <label for="" class="fw-semibold mb-2">Notes(Optional)</label>
                <textarea
                  class="form-control"
                  v-model="form.remarks"
                  id=""
                  rows="50"
                  cols="47"
                  style="height: 150px"
                  placeholder="Max 250 characters"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="col-md-6 px-5">
            <div class="align-items-center" style="display: flex">
              <div class="" style="width: 200px">
                <img
                  :src="booking_details.cover_image.url"
                  class="w-100 rounded"
                />
              </div>
              <div class="ps-3">
                <p class="mb-0 font-size-2 fw-semibold">
                  {{ booking_details.retreat_name }}
                </p>
                <span class="font-size-1">{{
                  booking_details.name
                }}</span>
              </div>
            </div>
            <div class="listss mt-3">
              <p class="fw-semibold font-size-2">Booking details</p>
              <ul class="list-unstyled font-size-1 fw-medium">
                <li class="pb-2">
                  {{ dateTime(booking_details.check_in) }} -
                  {{ dateTime(booking_details.check_out) }}
                </li>
                <li class="pb-2">
                  {{ booking_details.cottage_count }} Cottages
                </li>
                <li class="pb-2">
                  {{ booking_details.adults }} adults,
                  {{ booking_details.children }} children,
                  {{ booking_details.guests }} guests,
                  {{ booking_details.pets }} pets
                </li>
              </ul>

              <p class="fw-semibold font-size-2 mb-0">Payment details</p>
              <ul class="basic-list font-size-1 fw-medium basic-pad">
                <li class="d-flex justify-content-between">
                  <span
                    >Rent - {{ booking_details.nights }} Nights x
                    {{ booking_details.cottage_count }} Cottages</span
                  >
                  <span class="">₹ {{ booking_details.total }}</span>
                </li>

                <li
                  class="d-flex justify-content-between"
                  v-if="applyCreditTotheBooking"
                >
                  <span
                    >Credits - {{ booking_details.nights }} Nights x
                    {{ booking_details.cottage_count }} Cottages</span
                  >
                  <span class=""
                    >{{ booking_details.credit_required }} Credits</span
                  >
                </li>
                <li class="d-flex justify-content-between">
                  <span class="text-right">
                    <button
                      type="button"
                      @click="applyCreditToBooking"
                      class="btn btn-sm text-white me-5 px-4"
                      :disabled="disableCreditBtnFunction"
                      :class="{
                        'btn-success': !applyCreditTotheBooking,
                        'btn-danger': applyCreditTotheBooking,
                      }"
                    >
                      <span v-if="applyCreditTotheBooking">
                        Remove Credit
                      </span>
                      <span v-else>Apply Credit</span>
                    </button>
                  </span>
                </li>
              </ul>
              <hr />
              <p class="d-flex justify-content-between fw-semibold">
                <span class="">Total (Rupees)</span>
                <span class="">₹ {{ booking_details.total }}</span>
              </p>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div class="fixed-bottom bg-white">
          <hr class="mt-0 border-secondary" />
          <div class="row">
            <div class="col-md-6 mb-3 ps-5">
              <button
                type="button"
                @click="cancelBtn"
                class="btn bg-gray-200 border fw-light ms-5 px-4"
              >
                Cancel
              </button>
            </div>
            <div class="col-md-6 text-end mb-3 pe-5">
              <button class="btn bg-primary text-white me-5 px-4" :disabled="isBookingInProgress">
                Submit
              </button>
            </div>
          </div>
        </div>
      </footer>
    </form>
  </main>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      isBookingInProgress: false,
      form: {
        co_farmer_id: "",
        co_farmer_current_credit: "",
        mobile_number: "",
        email: "",
        amount_paid: "",
        transaction_id: "",
        remarks: "",
        retreat_id: "",
        cottage_id: "",
        adults: 0,
        children: 0,
        guests: 0,
        pets: 0,
        check_in: "",
        check_out: "",
        credit_required: 0,
        credit_applied: false,
        bookingToken:''
      },
      cofarmer_details: [],
      applyCreditTotheBooking: false,
      booking_details: {
        total: 0,
        cover_image: {
          url: "",
        },
        retreat_name: "",
        cottage_name: "",
        check_in: "",
        check_out: "",
        cottage_count: "",
        adults: "",
        children: "",
        guests: "",
        pets: "",
        nights: "",
        total_amount_with_credit: 0,
        total_amount_without_credit: 0,
        total_without_credit: "",
        total_with_credit: "",
        cofarmer_details: [],
      },
      disableCreditBtnFunction: true,
      disableCreditBtn: true,
    };
  },
  mounted() {
    this.checkAvailability();
    this.getCoFarmerDetails();
  },

  methods: {
    cancelBtn() {
      this.$router.go(-1);
    },
    bookingFormSubmit() {
      let {
        retreat_id,
        cottage_id,
        adults,
        children,
        guests,
        pets,
        check_in,
        check_out,
      } = this.$route.query;

      let {
        credit_applied,
        amount_paid,
        transaction_id,
        remarks,
        mobile_number,
        co_farmer_id,
        credit_required,
        email,
        bookingToken
      } = this.form;
      let { total } = this.booking_details;
      let data = {
        retreat_id,
        cottage_id,
        adults,
        children,
        guests,
        email,
        pets,
        check_in,
        check_out,
        total,
        credit_applied,
        amount_paid: amount_paid,
        transaction_id: transaction_id,
        remark: remarks,
        mobile: mobile_number,
        credits_count: credit_required,
        co_farmer_id: co_farmer_id,
        bookingToken:bookingToken
      };
      this.isBookingInProgress= true;
      this.$axios
        .post("/farm-stay/book", data)
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            alert(res.data.message);
            this.$router.push("/farm-stay/booking-list");
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          this.isBookingInProgress= false;
          console.log(err);
        });
    },
    selectedCofarmer() {
      console.log(this.form.co_farmer_id);
      let co_farmer_details = this.cofarmer_details.find(
        (d) => d.obj._id == this.form.co_farmer_id
      );
      console.log(co_farmer_details);
      this.form.mobile_number = co_farmer_details.obj.phone;
      this.form.email = co_farmer_details.obj.email;
      this.form.credit_applied = false;
      this.applyCreditTotheBooking = false;
      this.booking_details.total =
        this.booking_details.total_amount_without_credit;
      this.form.credit_required = 0;
      this.form.co_farmer_current_credit = co_farmer_details.obj.CurrentCredits;
      if (
        this.form.co_farmer_id != "" &&
        co_farmer_details.obj.CurrentCredits >=
          this.booking_details.credits_required
      ) {
        this.disableCreditBtnFunction = false;
      } else {
        this.disableCreditBtnFunction = true;
      }
    },
    applyCreditToBooking() {
      this.applyCreditTotheBooking = !this.applyCreditTotheBooking;
      if (
        this.applyCreditTotheBooking &&
        this.form.co_farmer_current_credit >=
          this.booking_details.credits_required
      ) {
        this.form.credit_applied = true;

        let credit_required =
          parseInt(this.booking_details.nights) *
          parseInt(this.booking_details.cottage_count);
        
        this.form.credit_required = this.booking_details.credit_required =
          credit_required;

        this.booking_details.total =
          this.booking_details.total_amount_with_credit;
      } else {
        this.form.credit_required = 0;
        this.booking_details.total =
          this.booking_details.total_amount_without_credit;
        this.form.credit_applied = true;
      }
    },
    dateTime(value) {
      if (value) return moment(value).format("DD-MM-YYYY");
      else return "";
    },
    async getCoFarmerDetails() {
      let coFarmerDetails = await this.$axios.get("/users?type=farmer");
      this.cofarmer_details = [];
      coFarmerDetails.data.data.forEach((cf) => {
        let text =
          cf.name + " - Credits" + " - " + cf.CurrentCredits.toFixed(2);

        this.cofarmer_details.push({
          id: cf._id,
          text: text,
          obj: cf,
        });
      });
    },
    async checkAvailability() {
      //console.log(this.$route.query);
      let {
        retreat_id,
        cottage_id,
        adults,
        children,
        guests,
        pets,
        check_in,
        check_out,
      } = this.$route.query;
      let data = {
        retreat_id,
        cottage_id,
        adults,
        children,
        guests,
        pets,
        check_in,
        check_out,
      };
      let availabilityResp = await this.$axios.get("/farm-stay/availability", {
        params: data,
      });
      
      this.booking_details = availabilityResp.data;
      this.booking_details.total =
      this.booking_details.total_amount_without_credit;
      this.booking_details.credits_required =
      this.booking_details.nights * this.booking_details.cottage_count;

      this.form.bookingToken =  this.booking_details.bookingToken;
    },
  },
};
</script>
<style>
.mb-15 {
  margin-bottom: 15rem;
}
.select2-container {
  width: 100% !important;
}


</style>