<template>
    <div>
        
        <div v-if="step <= 4">
            <main>
                <section>
                    <div class="container mt-5 mb-5">
                        <div class="d-flex justify-content-between">
                            <div class="">
                                <span 
                                    :class="{ 'avatar': (step >= 1) }">1</span>
                                <span class="fw-medium ms-2">Cottage information</span>
                            </div>
                            <div style="width: 150px;">
                                <hr>
                            </div>
                            <div class="">
                                <span 
                                    :class="{ 'avatar': (step >= 2), 'avatar-disbled': (step < 2) }">2</span>
                                <span class="fw-medium ms-2">Pricing</span>
                            </div>
                            <div style="width: 150px;">
                                <hr>
                            </div>
                            <div class="">
                                <span 
                                    :class="{ 'avatar': (step >= 3), 'avatar-disbled': (step < 3) }">3</span>
                                <span class="fw-medium ms-2">Discounts</span>
                            </div>
                            <div style="width: 150px;">
                                <hr>
                            </div>
                            <div class="">
                                <span 
                                    :class="{ 'avatar': (step >= 4), 'avatar-disbled': (step < 4) }">4</span>
                                <span class="fw-medium ms-2">Availability</span>
                            </div>

                        </div>
                    </div>
                </section>
                <div v-if="step == 1">

                    <section>
                        <div class="container">
                            <div class="row text-center mb-4 pt-5">
                                <div class="col-md-12">
                                    <h4 class="fw-semibold">Cottage Information</h4>
                                </div>
                            </div>
                            <div class="container2 mb-10">
                                <div class="centered">
                                    <div class="section-form">
                                        <div class="mb-4">
                                            <label for="" class="fw-semibold mb-2">Cottage name</label>
                                            <input type="text" 
                                            v-model="form.cottage_name" class="form-control"
                                                placeholder="Enter cottage name">
                                        </div>
                                        <div class="mb-3">
                                            <label for="" class="fw-semibold mb-2">No of cottages</label>
                                            <input type="number" 
                                            v-model="form.cottage_count" class="form-control"
                                                placeholder="Enter the count">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
                <div v-if="step == 2">

                    <section>
                        <div class="container">
                            <div class="row text-center mb-4">
                                <div class="col-md-12">
                                    <h4 class="fw-semibold">Add Pricing</h4>
                                    <span class="font-size-1 fw-semibold text-gray-300">Pricing is per cottage</span>
                                </div>
                            </div>
                            <div class="container2 mb-10">
                                <div class="centered">
                                    <div class="section-form">
                                        <div class="mb-4">
                                            <label for="" class="fw-semibold mb-2">Rack price</label>
                                            <input type="number"  v-model="form.rack_price" class="form-control" placeholder="6000">
                                        </div>
                                        <div class="mb-3">
                                            <label for="" class="fw-semibold mb-2">Price on weekdays <span
                                                    class="text-gray-300 fw-light">(Monday - Friday)</span> </label>
                                            <input type="number"  v-model="form.weekdays_price" class="form-control" placeholder="2500">
                                        </div>
                                        <div class="mb-3">
                                            <label for="" class="fw-semibold mb-2">Price on weekends <span
                                                    class="text-gray-300 fw-light">(Saturday - Sunday)</span> </label>
                                            <input type="number"  v-model="form.weekends_price" class="form-control"
                                                placeholder="Enter the price">
                                        </div>
                                        <div class="mb-3">
                                            <label for="" class="fw-semibold mb-2">Price on special days <span
                                                    class="text-gray-300 fw-light">(Add from Farm Stay)</span> </label>
                                            <input type="number"  v-model="form.special_days_price" class="form-control" placeholder="4000">
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <div class="me-3">
                                                <input type="checkbox" name="guest"
                                                value="true"
                                                 id="guest" v-model="form.guest_allowed"
                                                 @click="guestAllowedButton"
                                                 >&nbsp;
                                                <label for="guest" class="fw-semibold mb-2">Guest</label>
                                                <input type="number"  v-model="form.guest_fee" class="form-control"
                                                :disabled="!form.guest_allowed"
                                                placeholder="Enter the guest fee">
                                            </div>
                                            <div>
                                               
                                                <input type="checkbox"
                                                v-model="form.pet_allowed"
                                                value="true"
                                                @change="petAllowedButton"
                                                 name="pet" id="pet">&nbsp;


                                                <label for="pet" class="fw-semibold mb-2">Pet</label>
                                                <input type="number"  
                                                :disabled="!form.pet_allowed"
                                                v-model="form.pet_fee" class="form-control"
                                                    placeholder="Enter the pet fee">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div v-if="step == 3">

                    <section>
                        <div class="container">
                            <div class="row text-center mb-4">
                                <div class="col-md-12">
                                    <h4 class="fw-semibold">Discounts</h4>
                                </div>
                            </div>
                            <div class="container2 mb-10">
                                <div class="centered">
                                    <div class="section-form">
                                        <div class="mb-4">
                                            <label for="" class="fw-semibold mb-2">Discount with credits</label>
                                            <input type="number" v-model="form.discount_with_credits" class="form-control" placeholder="500">
                                        </div>
                                        <div class="mb-4">
                                            <label for="" class="fw-semibold mb-2">Discount without credits</label>
                                            <input type="number" v-model="form.discount_without_credits" class="form-control" placeholder="100"
                                            @keyup="DiscountWithoutCreditChanged">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div v-if="step == 4">

                    <section>
                        <div class="container">
                            <div class="row text-center mb-4">
                                <div class="col-md-12">
                                    <h4 class="fw-semibold">Availability</h4>
                                </div>
                            </div>
                            <div class="container2 mb-10">
                                <div class="centered">
                                    <div class="section-form">
                                     
                                        <div class="mb-4">
                                            <label for="" class="fw-semibold mb-2">Maximum adults allowed?</label>
                                            <input type="number" v-model="form.max_adults_allowed" class="form-control" placeholder="3">
                                        </div>
                                        <div class="mb-4">
                                            <label for="" class="fw-semibold mb-2">Maximum children allowed?</label>
                                            <input type="number" v-model="form.max_child_allowed" class="form-control" placeholder="2">
                                        </div>
                                        <div class="mb-4">
                                            <label for="" class="fw-semibold mb-2">Maximum members allowed?</label>
                                            <input type="number" 
                                           
                                             class="form-control" placeholder="4"
                                            :value="calculateTotalMember"    
                                            disabled>
                                        </div>
                                        <div class="mb-4">
                                            <label for="" class="fw-semibold mb-2">Maximum pets allowed?</label>
                                            <input type="number" v-model="form.max_pets_allowed"
                                             class="form-control" 
                                             placeholder="1"
                                             :disabled="!form.pet_allowed"
                                             >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                
                <footer>
                    <div class="fixed-bottom bg-white">
                        <hr class="mt-0">
                        <div class="row">
                            <div class="col-md-6 mb-3 ps-5">
                                <button type="button" class="btn bg-gray fw-light ms-5 px-4 border" v-if="step == 1"
                                    @click="$router.go(-1)">Cancel</button>
                                <button type="button" class="btn bg-gray fw-light ms-5 px-4 border" v-if="step > 1"
                                    @click="BackPage()">Back</button>
                            </div>
                            <div class="col-md-6 text-end mb-3 pe-5">
                                <button v-if="step < 4" type="button" class="btn bg-primary fw-light text-white me-5 px-4"
                                    @click="nextPage()"
                                    :disabled="enablenextstepbutton"
                                    >Next</button>
                                <button v-if="step == 4" type="button" class="btn bg-primary fw-light text-white me-5 px-4"
                                    @click="saveData()"
                                    :disabled="enablenextstepbutton"
                                    >Save</button>
                            </div>
                        </div>
                    </div>
                </footer>
            </main>
        </div>
        <div v-else>
            <main class="div-center">
                <section>
                    <div class="container">
                        <div class="text-center">
                            <img src="/assets/img/icons/done.png" alt="">
                        </div>

                        <div class="row text-center mt-4 mb-3">
                            <div class="col-md-12">
                                <h2 class="fw-semibold">Cottage is added</h2>
                            </div>
                        </div>
                        <div class="row text-center mb-4">
                            <div class="col-md-12">
                                <router-link 
                                to="/farm-stay/retreat"
                                class="fw-light btn bg-primary text-white btn-l">
                                    Home
                                </router-link>

                            </div>
                        </div>

                    </div>
                </section>

            </main>
        </div>
    </div>
</template>
<script>


export default {
    data() {
        return {
            step: 1,
            form:{
                cottage_name:'',
                cottage_count:0,
                rack_price:0,
                weekdays_price:0,
                weekends_price:0,
                special_days_price:0,
                guest_fee:0,
                pet_fee:0,
                pet_allowed:false,
                guest_allowed:false,
                discount_with_credits:0,
                discount_without_credits:0,
                max_member_allowed:0,
                max_adults_allowed:1,
                max_child_allowed:0,
                max_pets_allowed:0,
            }
        }
    },
    computed: {
        calculateTotalMember(){
            return this.form.max_member_allowed = 
            this.form.max_adults_allowed + this.form.max_child_allowed;

        },
        enablenextstepbutton() {
            let currentStep = this.step;
            if (currentStep == 1) {
                if (this.form.cottage_name && this.form.cottage_count) {
                    return false;
                } else {
                    return true;
                }
            } else if (currentStep == 2) {
                if (this.form.rack_price 
                     && this.form.weekdays_price
                     && this.form.weekends_price 
                     && this.form.special_days_price
                
                ) {
                    let currentStatus = false;
                    if(this.form.guest_allowed){
                        if(this.form.guest_fee >= 0 ){
                            currentStatus = false;
                        }else{
                            currentStatus =  true;
                        }
                    }

                    if(this.form.pet_allowed){
                        if(this.form.pet_fee >= 0){
                            currentStatus =  false;
                        }else{
                            currentStatus =  true;
                        }
                    }
                    return currentStatus;
                   
                } else {
                    return true;
                }
            } else if (currentStep == 3) {
                if (this.form.discount_with_credits >= 0 && this.form.discount_without_credits >= 0 ) {
                    return false;
                } else {
                    return true;
                }
            } else if (currentStep == 4) {
                if (this.form.max_member_allowed 
                && this.form.max_adults_allowed
                && this.form.max_child_allowed >= 0
               ) 
                {
                    return false;
                } else {
                    return true;
                }
            }
        }
    },
    methods: {
      
        DiscountWithoutCreditChanged(){
            if(this.form.discount_without_credits > this.form.discount_with_credits ){
                 alert('Discount without credit should be less  or equal than with credit discount'); 
                 this.form.discount_without_credits  = this.form.discount_with_credits 
            }
        },
        guestAllowedButton(){
            
            if(this.form.guest_allowed == true){
                this.form.guest_fee = 0;
            }
        },
        petAllowedButton(){
           
            if(this.form.pet_allowed == true){
                this.form.pet_fee = 0;
            }
            if(this.form.pet_allowed && this.form.max_pets_allowed == 0){
                alert("Max Pet Allowed count should be more than zero if pet allowerd");
                this.form.max_pets_allowed = 1;
            }
        },
        BackPage() {
            this.step--;
        },
        nextPage() {
            this.step++;
        },
        saveData() {
            console.log(this.form);
            
            let retreat_id = this.$route.params.retreat_id 
            this.$axios.post('/farm-stay/retreat/'+retreat_id+'/cottage',this.form)
            .then(res => {
                console.log(res);
                this.step++;
            })
        }


    }
}
</script>