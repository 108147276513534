<template>

              <hr class="mt-0" />
              <div class="row align-items-center mt-4">
                <div class="col-md-6">
                  <div class="w-50">
                    <input
                      type="search"
                      name=""
                      id=""
                      class="form-control h-75"
                      placeholder="Search"
                      v-model="form.query"
                      @input="getList"
                    />
                  </div>
                </div>
                <div class="col-md-6 text-end">
                  <div>
                    <router-link
                      to="/farm-stay/retreat/create"
                      class="btn bg-primary text-white font-size-1"
                      >Add Retreat</router-link
                    >
                  </div>
                </div>
              </div>
              <div v-if="!isLoading">
                <div class="row mt-5 mx-1">
                  <div
                    class="card p-0 mb-3 me-2"
                    style="width: 300px; height: 357px"
                    v-for="(retreat, i) in retreats"
                    :key="i"
                  >
                    <img
                      :src="retreat.image.url"
                      class="card-img-top"
                      alt="..."
                      width="300"
                      height="200"
                    />
                    <div class="card-body">
                      <div class="title" style="height: 89px">
                        <h5 class="card-title">
                          {{ retreat.display_name.slice(0, 20) }}
                        </h5>
                        <p
                          class="card-text font-size-1 fw-medium text-gray-300"
                        >
                          {{
                            retreat.description.replace(/\n/g, "").slice(0, 32)
                          }}
                        </p>
                      </div>

                      <router-link
                        :to="'/farm-stay/retreat/' + retreat._id + '/cottages'"
                        class="btn border font-size-12 text-gray-300 fw-medium border-black py-1 px-3"
                      >
                        View cottages</router-link
                      >

                      <router-link
                        :to="'/farm-stay/retreat/' + retreat._id + '/edit'"
                        class="btn border font-size-12 text-gray-300 fw-medium border-black py-1 px-3"
                        style="margin-left: 5px !important"
                      >
                        Edit</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>Loading...</div>
</template>
<script>
export default {
  data() {
    return {
      isLoading: false,
      retreats: [],
      form: {
        query: "",
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let query = {};
      if (this.form.query) {
        query.display_name = this.form.query;
      }
      this.isLoading = true;
      this.$axios
        .get("/farm-stay/retreat", {
          params: query,
        })
        .then((resp) => {
          this.isLoading = false;
          this.retreats = resp.data.data;
        });
    },
  },
};
</script>
