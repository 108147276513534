<template>
  <div>
    <hr class="mt-0" />
    <div class="row" v-if="isLoading">
      <div class="col-md-6 border-end">
        <p class="fw-semibold font-size-2">
          Booking details

          <span
            type="span"
            class="badge bg-success text-white font-size-12"
            v-if="booking_details.booking_source == 2"
            >Mobile</span
          >
          <span
            type="span"
            class="badge bg-danger text-white font-size-12"
            v-if="booking_details.booking_source == 1"
            >Admin
          </span>
        </p>
        <ul class="basic-list font-size-1 fw-semibold basic-pad pe-5">
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">Booking id</span>
            <span class="">
              {{ booking_details.bookingId }}
            </span>
          </li>
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">Transaction id</span>
            <span class="">
              {{ booking_details.transaction_id }}
            </span>
          </li>
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">Booking date</span>
            <span class="">
              {{ dateTime(booking_details.created_ts) }}</span
            >
          </li>
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">Retreat</span>
            <span class="">{{
              booking_details._farm_retreat_id.display_name
            }}</span>
          </li>
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">Cottage</span>
            <span class="">{{
              booking_details._farm_retreat_cottage_id.cottage_name
            }}</span>
          </li>
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">No of cottages</span>
            <span class="">{{ booking_details.cottage_count }}</span>
          </li>
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">Check in</span>
            <span class="">{{
              dateTime(booking_details.check_in)
            }}</span>
          </li>
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">Check out</span>
            <span class="">{{
              dateTime(booking_details.check_out)
            }}</span>
          </li>
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">Days & Nights</span>
            <span class=""
              >{{ booking_details.day_counts }}D
              {{ booking_details.night_counts }}N</span
            >
          </li>
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">Credits used</span>
            <span class="">{{ booking_details.credit_count }}</span>
          </li>
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">Adults</span>
            <span class="">{{ booking_details.adult_counts }}</span>
          </li>
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">Children</span>
            <span class="">{{ booking_details.child_counts }}</span>
          </li>
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">Guests</span>
            <span class="">{{ booking_details.guest_counts }}</span>
          </li>
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">Pets</span>
            <span class="">{{ booking_details.pets_counts }}</span>
          </li>
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">Amount paid</span>
            <span class="">Rs {{ booking_details.amount_paid }}</span>
          </li>
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">Remarks</span>
            <span class="">{{ booking_details.remark }}</span>
          </li>
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">Status</span>

            <span
              type="button"
              class="badge bg-primary text-white font-size-12"
              v-if="booking_details.status == 1"
              >Confirmed</span
            >
            <span
              type="span"
              class="badge bg-warning text-white font-size-12"
              v-if="booking_details.status == 2"
              >In Progress</span
            >
            <!-- <span type="span" class="badge bg-refunded text-white font-size-12"
                    v-if="booking_details.status == 3">Refunded</span> -->
            <span
              type="span"
              class="badge bg-danger text-white font-size-12"
              v-if="booking_details.status == 3"
              >Cancelled</span
            >
            <span
              type="span"
              class="badge bg-danger text-white font-size-12"
              v-if="booking_details.status == 4"
              >Payment Failed</span
            >
          </li>
          <template v-if="booking_details.status == 3">
            <li class="d-flex justify-content-between">
              <span class="text-gray-300">Refunded Amount</span>
              Rs. {{ booking_details.refunded_amount }}
            </li>
            <li class="d-flex justify-content-between">
              <span class="text-gray-300">Refunded Id</span>
              {{ booking_details.refund_id }}
            </li>
            <li class="d-flex justify-content-between">
              <span class="text-gray-300"
                >Reason for cancellation</span
              >
              {{ booking_details.refund_remarks }}
            </li>
            <li class="d-flex justify-content-between">
              <span class="text-gray-300"
                >Cancelled Date and Time</span
              >
              {{ dateAndTime(booking_details.refund_ts) }}
            </li>
            <li class="d-flex justify-content-between">
              <span class="text-gray-300">Refund Settled Date</span>
              {{
                booking_details.refund_settled_date
                  ? dateAndTime(booking_details.refund_settled_date)
                  : null
              }}
            </li>
          </template>
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">Payment / Refund Status</span>

            <span
              type="button"
              class="badge bg-info text-white font-size-12"
              v-if="booking_details.payment_status == 0"
              >INITIATED</span
            >
            <span
              type="button"
              class="badge bg-info text-white font-size-12"
              v-if="booking_details.payment_status == 1"
              >PAID</span
            >

            <span
              type="span"
              class="badge bg-info text-white font-size-12"
              v-if="booking_details.payment_status == 2"
              >FAILED</span
            >
            <span
              type="span"
              class="badge bg-info text-white font-size-12"
              v-if="booking_details.payment_status == 3"
              >REFUND QUEUED</span
            >
            <span
              type="span"
              class="badge bg-info text-white font-size-12"
              v-if="booking_details.payment_status == 4"
              >REFUND APPROVED</span
            >
            <span
              type="span"
              class="badge bg-info text-white font-size-12"
              v-if="booking_details.payment_status == 5"
              >REFUNDED</span
            >
          </li>
        </ul>
      </div>
      <div class="col-md-6 px-5">
        <p class="fw-semibold font-size-2">Customer details</p>
        <ul class="basic-list font-size-1 fw-semibold basic-pad">
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">Name</span>
            <span class="" v-if="booking_details.user_id">{{
              booking_details.user_id.name
            }}</span>
          </li>
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">Mobile number</span>
            {{ booking_details.mobile }}
          </li>
          <li class="d-flex justify-content-between">
            <span class="text-gray-300">Email address</span>
            {{ booking_details.email }}
          </li>
        </ul>
      </div>
    </div>

    <div class="row mt-3 mb-5">
      <div class="col-md-12 text-end">
        <span v-if="booking_details.status == 2">
          <button
            type="button"
            class="btn bg-gray fw-light px-4 border me-4"
            @click="goBack"
          >
            Close
          </button>

          <button
            type="button"
            class="btn bg-refunded fw-light text-white px-4"
            @click="cancelBooking(3)"
            disabled="true"
          >
            Refund Completed
          </button>
        </span>

        <span v-if="booking_details.status == 3">
          <button
            type="button"
            class="btn bg-gray fw-light px-4 border me-4"
            @click="goBack"
          >
            Close
          </button>
        </span>

        <span v-if="booking_details.status == 1">
          <a
            type="button"
            class="btn bg-gray fw-light px-4 border me-4"
            @click="goBack"
            >Close</a
          >

          <button
            v-if="booking_details.booking_source == 1"
            type="button"
            class="btn bg-refunded fw-light text-white px-4"
            @click="cancelBooking(2)"
          >
            Cancel booking
          </button>
        </span>
      </div>
    </div>

    <div
      class="modal fade show"
      :class="{ showPopup: 'show' }"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
      :style="{ display: displayType }"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-3" style="width: 460px">
          <form
            action=""
            @submit.prevent="cancellationFormSubmitted(3)"
          >
            <div class="modal-body">
              <h5 class="text-center mb-4">Cancel booking?</h5>
              <div class="textarea">
                <span class="fw-semibold"
                  >Reason for cancellation</span
                >
                <textarea
                  name=""
                  id=""
                  cols="45"
                  rows="10"
                  placeholder="Max 100 characters"
                  class="mt-2 p-2"
                  style="height: 160px"
                  v-model="form.reason_for_cancellation"
                  maxlength="100"
                ></textarea>
              </div>
              <div class="radio-buttons my-3">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="no_refund"
                    v-model="form.cancellation_type"
                    @change="typeModified"
                    value="No Refund"
                  />
                  <label class="form-check-label" for="no_refund"
                    >No Refund</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="refunded_amount"
                    v-model="form.cancellation_type"
                    @change="typeModified"
                    value="Refunded amount"
                  />
                  <label
                    class="form-check-label"
                    for="refunded_amount"
                    >Refunded amount</label
                  >
                </div>
              </div>
              <div
                v-if="form.cancellation_type == 'Refunded amount'"
                class="mb-2"
              >
                <label for="rf_amount_field" class="fw-semibold"
                  >Refunded amount</label
                >
                <input
                  type="number"
                  name="rf_amount_field"
                  id="rf_amount_field"
                  class="form-control mt-2"
                  maxlength="10"
                  v-model="form.refunded_amount"
                  placeholder="Amount in rupees"
                />
              </div>
              <div>
                <label for="rf_amount_field" class="fw-semibold"
                  >Refunded Credit Point</label
                >
                <input
                  type="number"
                  class="form-control"
                  v-model="form.credit_point_refund"
                  readonly
                />
              </div>
            </div>

            <div class="modal-footer justify-content-center border-0">
              <button
                type="button"
                class="btn bg-transparent border"
                @click="togglePopup()"
              >
                Close
              </button>
              <button type="submit" class="btn bg-primary text-white">
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" v-if="showPopup"></div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      showPopup: false,
      data: [],
      booking_details: "",
      paymentDetails: [],
      isLoading: false,
      displayType: "none",
      form: {
        isCreditApplied: false,
        credit_point_refund: 0,
        reason_for_cancellation: "",
        cancellation_type: "No Refund",
        refunded_amount: 0,
        type: 0,
        check_in: "",
        check_out: "",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    dateAndTime(value) {
      if (value) return moment(value).format("DD-MM-YYYY HH:MM:SS");
      else return "";
    },
    dateFormat(value) {
      if (value) return moment(value).format("DD-MM-YYYY");
      else return "";
    },
    typeModified() {
      if (this.form.cancellation_type == "No Refund") {
        this.form.refunded_amount = 0;
      }
    },
    getData() {
      let booking_id = this.$route.params.booking_id;
      this.$axios
        .get("/farm-stay/bookings/" + booking_id)
        .then((res) => {
          if (res.data.success) {
            this.isLoading = true;
            this.booking_details = res.data.data;
            this.paymentDetails = res.data.paymentDetails;
          }
        });
    },
    cancelBooking(type) {
      this.togglePopup();
    },
    cancellationFormSubmitted(type) {
      let booking_id = this.$route.params.booking_id;
      this.form.type = type;
      this.$axios
        .put(
          "/farm-stay/bookings/" + booking_id + "/status",
          this.form
        )
        .then((res) => {
          if (res.data.success) {
            alert(res.data.message);
            this.isLoading = true;
            location.reload();
          }
        });
    },
    getCancellationDetails() {
      let booking_id = this.$route.params.booking_id;
      this.$axios
        .get(
          "/farm-stay/bookings/" + booking_id + "/cancellation",
          this.form
        )
        .then((res) => {
          console.log(res);
          this.form.refunded_amount = res.data.refund_amount;
          this.form.credit_point_refund =
            res.data.credit_point_refund;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    togglePopup() {
      this.showPopup = !this.showPopup;
      if (this.displayType == "block") {
        this.displayType = "none";
      } else {
        this.displayType = "block";
        this.getCancellationDetails();
      }
    },
    dateTime(value) {
      if (value) return moment(value).format("DD-MM-YYYY");
      else return "";
    },
  },
};
</script>
