<template>
  <div
    class="modal fade modal-xl"
    id="activityEditModal"
    tabindex="-1"
    aria-labelledby="activityEditModal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <h4 class="text-center text-uppercase">Edit Activity</h4>

          <form action="" class="m-4">
            <div class="row">
             
             
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">
                    <div class="col-md-6 mb-4">
                      <label for="activityType1" class="mb-2 fw-bold"
                        >Activity Type</label
                      >
                      <Select2
                        v-model="forms.activityTypeIds"
                        :options="activityTypeList"
                        class="form-select2"
                        placeholder="Select farm"
                        id="activityType1"
                        :settings="{
                          allowClear: true,
                        }"
                        @change="activityTypeSelected($event)"
                        @select="activityTypeSelected($event)"
                      >
                      </Select2>
                    </div>
                  </div>
                  <div class="col-md-6 mb-4">
                    <label for="frequency1" class="mb-2 fw-bold"
                      >Frequency</label
                    >
                    <Select2
                      v-model="forms.activityTypeIds"
                      :options="activityTypeList"
                      class="form-select2"
                      placeholder="Select Frequency"
                      id="frequency1"
                      :settings="{
                        allowClear: true,
                      }"
                      @change="activityTypeSelected($event)"
                      @select="activityTypeSelected($event)"
                    >
                    </Select2>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">
                    <div class="col-md-6 mb-4">
                      <label for="workers1" class="mb-2 fw-bold">Workers</label>
                      <Select2
                        v-model="forms.activityTypeIds"
                        :options="activityTypeList"
                        class="form-select2"
                        placeholder="No of Workers"
                        id="workers1"
                        :settings="{
                          allowClear: true,
                        }"
                        @change="activityTypeSelected($event)"
                        @select="activityTypeSelected($event)"
                      >
                      </Select2>
                    </div>
                  </div>
                  <div class="col-md-6 mb-4">
                    <label for="resource1" class="mb-2 fw-bold">Resource</label>
                    <Select2
                      v-model="forms.activityTypeIds"
                      :options="activityTypeList"
                      class="form-select2"
                      placeholder="Add resoucrce"
                      id="resource1"
                      :settings="{
                        allowClear: true,
                      }"
                      @change="activityTypeSelected($event)"
                      @select="activityTypeSelected($event)"
                    >
                    </Select2>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <label for="" class="mb-2 fw-bold">Image</label>
                
                <template v-for="(img, i) in forms.images" :key="i">
                    <div
                      class="bg-image hover-overlay ripple shadow-1-strong rounded"
                    >
                      <img :src="img.url" style="height: 150px; width: 220px" />
                    </div>
                </template>
            
                <div
                  class="upload-area__drop-zoon drop-zoon"
                  v-bind="getRootProps()"
                >
                  <input
                    id="fileInput"
                    class="drop-zoon__file-input"
                    accept="image/*"
                    v-bind="getInputProps()"
                  />
                  <span class="drop-zoon__icon">
                    <i class="fa fa-file-image-o" aria-hidden="true"></i>
                  </span>
                  <p v-if="isDragActive">Drop the files here ...</p>
                  <p v-else>
                    Drag 'n' drop some files here, or click to select files
                  </p>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <label for="description1" class="mb-2 fw-bold"
                  >Description</label
                >
                <textarea
                  name=""
                  id="description1"
                  cols="30"
                  style="height: 165px"
                  class="form-control"
                  placeholder="Max 200 Character"
                ></textarea>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6 mb-4">
                    <div class="col-md-6">
                      <label for="dp1" class="mb-2 fw-bold">Start Date</label>
                      <VueDatePicker
                        id="dp1"
                        v-model="forms.dates"
                        :enable-time-picker="false"
                        placeholder="Start Date"
                        position="left"
                        auto-apply
                      ></VueDatePicker>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="dp2" class="mb-2 fw-bold">End Date</label>
                    <VueDatePicker
                      id="dp2"
                      v-model="forms.dates"
                      :enable-time-picker="false"
                      placeholder="End Date"
                      position="left"
                      auto-apply
                    ></VueDatePicker>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="row">
                  <div class="col-md-6">
                    <div class="col-md-6">
                      <label for="mySelect0" class="mb-2 fw-bold">Status</label>
                      <Select2
                        v-model="forms.activityTypeIds"
                        :options="activityTypeList"
                        class="form-select2"
                        placeholder="Choose status"
                        id="mySelect0"
                        :settings="{
                          allowClear: true,
                        }"
                        @change="activityTypeSelected($event)"
                        @select="activityTypeSelected($event)"
                      >
                      </Select2>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="mySelect0" class="mb-2 fw-bold">Progress</label>
                    <Select2
                      v-model="forms.activityTypeIds"
                      :options="activityTypeList"
                      class="form-select2"
                      placeholder="Select Progress"
                      id="mySelect0"
                      :settings="{
                        allowClear: true,
                      }"
                      @change="activityTypeSelected($event)"
                      @select="activityTypeSelected($event)"
                    >
                    </Select2>
                  </div>
                </div>
              </div>
           
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="bg-primary-inverse px-3 py-2 rounded-1 border-0"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="bg-primary px-3 py-2 rounded-1 border-0 text-white mr-2"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useDropzone } from "vue3-dropzone";
import VueDatePicker from "@vuepic/vue-datepicker";
export default {
    components:{
        VueDatePicker
    },
    data(){
        return {
            zones:[],
            plots:[],
            farms:[],
            activityTypeList:[],
            forms: {
                activityTypeIds: "",
                projectId: "",
                ZoneId: "",
                PlotId: "",
                images:[{
                  url :'https://images.pexels.com/photos/1563355/pexels-photo-1563355.jpeg?cs=srgb&dl=pexels-thatguycraig000-1563355.jpg&fm=jpg'
                },{
                  url : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOSUOrj8MRrS_rQ-h8LMAlnoFKj0K4NiC1Sw&s'
                },{
                  url : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOSUOrj8MRrS_rQ-h8LMAlnoFKj0K4NiC1Sw&s'
                }]
            },
        }
    },
    setup() {
    const url = "/upload"; //
    const saveFiles = (files, vm) => {
      const formData = new FormData(); // pass data as a form
      for (var x = 0; x < files.length; x++) {
        formData.append("images", files[x]);
      }
      vm.$axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.info(response.data);
          if (response.data.success) {
            response.data.data.forEach((e) => {
              vm.form.images.push(e);
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };
    function onDrop(acceptFiles, rejectReasons) {
      console.log(acceptFiles);
      console.log(rejectReasons);

      let file_count = window.vm.form.images.length + acceptFiles.length;
      if (file_count > 5) {
        alert("5 Max file can be uploaded");
      } else if (rejectReasons.length == 0) saveFiles(acceptFiles, window.vm);
      else alert("5 Max file can be uploaded");
    }

    const { getRootProps, getInputProps, inputRef, ...rest } = useDropzone({
      onDrop,
      multiple: true,
      maxFiles: 5,
      noClick: false,
    });

    return {
      getRootProps,
      getInputProps,
      inputRef,
      ...rest,
    };
  },
}
</script>
<style scoped>
  .upload-area__drop-zoon{
    margin-top: 0px !important;
  }
</style>