<template>
    <div class="container-fluid">
    <div class="row">
      <div class="col-md-2 nav-border-right">
        <sideNavBar></sideNavBar>
      </div>
      <div class="col-md-10">
        <component :is="loadCurrenTabComponent" ref="currentTabComponent"
        />
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import { useRouter } from "vue-router";  
import {markRaw} from "vue";   
import sideNavBar from "@/components/navbars/navbar.vue";
import farmStaySubNavBar from "@/components/navbars/farmStaySubNavBar.vue";
import reportModuleSubNavBar from "@/components/navbars/ReportModuleSubNavBar.vue";
export default {
  data(){
    return {
      moduleName: '',
      currenTabComponent: false
    }
  },
  components :{
    sideNavBar
  },
  computed :{
    loadCurrenTabComponent()
    {
      const router = useRouter();
      let showNavbar = router.currentRoute.value.meta.showNavbar;
      this.moduleName  = router.currentRoute.value.meta.name;
      switch(this.moduleName) {
        case 'FarmStayModule':
           this.currenTabComponent = (showNavbar) ? markRaw(farmStaySubNavBar) : '';
          break;
        case 'ReportsModule':
           this.currenTabComponent = (showNavbar) ?  markRaw(reportModuleSubNavBar) : '';
          break;
        default:
            this.currenTabComponent = false;
      }
      return this.currenTabComponent;
      
    }
  }
}
</script>