<template>
  <div>
    <nav class="navbar navbar-expand-lg bg-light sticky-top nav-border">
      <div class="container-fluid">
        <router-link to="/farm-stay/retreat/bookings">
          <img
            src="/assets/img/logo/logo.png"
            alt=""
            width="200"
            class="imgBg"
          />
        </router-link>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        
        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <div class="btn-group">
            <span
              class="fw-semibold bg-blue text-white rounded-circle p-1 position-relative"
              data-bs-toggle="dropdown"
              data-bs-display="static"
              aria-expanded="false"
              >DM</span
            >
            <ul class="dropdown-menu dropdown-menu-lg-end">
              <li><a class="dropdown-item" href="#">Log out</a></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <router-view />
  </div>
</template>

<style>
.imgBg {
  mix-blend-mode: multiply;
}
.nav-border {
  border-bottom: 1px solid #EFEBE9;
}
.nav-border-right {
  border-right: 1px solid #EFEBE9;
}
:root {
  --ms-border-color: #050505
}
/* .select2-container{
  width: 100% !important;
} */
.form-select2{
  width: 100%
}
.font-weight-bold {
  font-weight: 800;
}
.active-top {
    color: #77b47b;
    border-bottom: 1px solid #77b47b !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 40px !important;
}
</style>
