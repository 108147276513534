<template>
  <div>
 
    <div class="mt-4 mb-4">
      <div class="row">
        <div class="col-md-3">
          <Multiselect
            mode="multiple"
            :clear-on-search="true"
            :searchable="true"
            @select="ActivityTypeChanged"
            v-model="forms.activityTypeId"
            :options="activityType"
            placeholder="Activity Type"
            :classes="{
              containerActive: '',
            }"
          >
            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label">
                <input type="checkbox" />&nbsp; {{ value }}
              </div>
            </template>
            <template v-slot:option="{ option }">
              <input type="checkbox" :checked="isSelected(option.id)" />&nbsp;
              {{ option.label }}
            </template>
          </Multiselect>
        </div>
        <div class="col-md-3">
          <Select2
            v-model="forms.ProjectId"
            :options="projects"
            class=""
            placeholder="Select Project"
            id="rptActProjId"
            :settings="{
              allowClear: true,
            }"
            @change="ProjectSelected($event)"
            @select="ProjectSelected($event)"
          >
          </Select2>
        </div>
        <div class="col-md-3">
          <Multiselect
            mode="multiple"
            :clear-on-search="true"
            :searchable="true"
            @select="ZoneChanged"
            @clear="ZoneChanged"
            v-model="forms.ZoneId"
            :options="zones"
            placeholder="Select Zone"
            ref="zoneSelect"
            :classes="{
              containerActive: '',
            }"
          >
            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label">
                <input type="checkbox" />&nbsp; {{ value }}
              </div>
            </template>
            <template v-slot:option="{ option }">
              <input type="checkbox" :checked="isSelected(option.id)" />&nbsp;{{
                option.label
              }}
            </template>
          </Multiselect>
        </div>
        <div class="col-md-3">
          <Select2
            v-model="forms.PlotId"
            :options="plots"
            class=""
            placeholder="Select Plot"
            id="rptActPlotId"
            :settings="{
              allowClear: true,
            }"
          >
          </Select2>
        </div>
        <div class="col-md-3 mt-2">
          <VueDatePicker
            v-model="forms.dates"
            :enable-time-picker="false"
            range
            placeholder="Date Range"
            position="left"
            auto-apply
          ></VueDatePicker>
        </div>
        <div class="col-md-6 mt-2">
          <button
            class="bg-primary px-3 py-2 rounded-1 border-0 text-white mr-2"
            @click="getData"
          >
            Search
          </button>

          <dropDown
            class="mr-2"
            :btnName="'Download'"
            :menuItems="dropDownItems"
            @menuItemClick="handleMenuItemClick"
          ></dropDown>
          <button
            class="bg-primary-inverse px-3 py-2 rounded-1 text-muted"
            @click="resetFormData"
          >
            <i class="fa fa-repeat fa-rotate-left"></i>
          </button>
        </div>
      </div>
    </div>

    <div v-if="!isLoading && isDataLoaded">
      <div class="row">
        <div class="col-md-4">
          <div class="card text-center p-3" style="background-color: #f5f5f5">
            <img
              src="@/assets/inventory_2.svg"
              height="50"
              width="50"
              class="center mb-3"
            />
            Total Plots
            <h4 class="mt-2">{{ totalPlots }}</h4>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card text-center p-3" style="background-color: #f5f5f5">
            <img
              src="@/assets/inventory_2.svg"
              height="50"
              width="50"
              class="center mb-3"
            />
            Completed plot activities
            <h4 class="mt-2">{{ completedPlotActivities }}</h4>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card text-center p-3" style="background-color: #f5f5f5">
            <img
              src="@/assets/inventory_2.svg"
              height="50"
              width="50"
              class="center mb-0"
            />
            Pending plot activities
            <h4 class="">{{ pendingPlotActivities }}</h4>
            <button
              style="font-size: 13px"
              class="btn btn-link btn-sm"
              @click="openModal"
            >
              View
            </button>
          </div>
        </div>
      </div>
      <div
        class="modal"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title text-center text-uppercase"
                id="exampleModalLabel"
              >
                Pending Plot Activities
              </h5>
            </div>
            <div class="modal-body">
              <table class="table" id="temp-target1">
                <thead class="font-size-12">
                  <tr>
                    <th>Activity type</th>
                    <th>Project</th>
                    <th>Zone</th>
                    <th>Plot no</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    class="font-size-1 fw-semibold"
                    v-for="(d, i) in cSVPendingPlots"
                    :key="i"
                  >
                    <td>{{ d.activityType }}</td>
                    <td>{{ d.project }}</td>
                    <td>{{ d.zone }}</td>
                    <td>{{ d.plot }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn bg-gray fw-light px-4 border me-4"
                data-bs-dismiss="modal"
                @click="closeModal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn bg-primary text-white"
                data-bs-dismiss="modal"
                @click="downloadPendingAsCSV"
              >
                Download As CSV
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table" id="temp-target">
          <thead class="font-size-12">
            <tr>
              <th>Project</th>
              <th>Zone</th>
              <th>Plot no</th>
              <th>Activity type</th>
              <th>Activity details</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr class="font-size-1" v-for="(d, i) in data" :key="i">
              <td>{{ d.project }}</td>
              <td>{{ d.zone }}</td>
              <td>{{ d.plot }}</td>
              <td>{{ d.activityType }}</td>
              <td>{{ d.activityDetails }}</td>
              <td>{{ dateFormat(d.dateOfCompletion) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else>
      <span v-if="isLoading">{{ LoadingMsg }}</span>
      <div class="text-center" v-else>
        <img src="@/assets/reports_unavailable.png" alt="" srcset="" />
        <p class="font-weight-bold">
          Use the filters above to find the data you need.
        </p>
      </div>
    </div>
  </div>
</template>
  
  <script>
import moment from "moment";
import VueDatePicker from "@vuepic/vue-datepicker";
import JsonToCSV from "@/components/JsonToCSV";

import dropDown from "@/components/DropDown.vue";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    VueDatePicker,
    JsonToCSV,
    dropDown,
    Multiselect,
  },
  data() {
    return {
      LoadingMsg: "Loading ...",
      isLoading: false,
      isModalOpen: false, // Initially modal is closed
      data: [],
      projects: [],
      zones: [],
      plots: [],
      activityType: [],
      pendingPlots: [],
      cSVPendingPlots: [],
      csv_data: [],
      limit: 10,
      page: 1,
      isPreviousBtn: true,
      isNextBtn: true,
      total_page: 1,
      totalPlots: 0,
      completedPlotActivities: 0,
      pendingPlotActivities: 0,
      isDataLoaded: false,
      activitiesNames: "",
      plotsNames: "",
      projectNames: "",
      zoneNames: "",
      startDate: "",
      endDate: "",
      forms: {
        activityTypeId: [],
        ProjectId: "",
        ZoneId: [],
        PlotId: "",
        dates: "",
      },
      dropDownItems: [
        { value: "csv", displayName: "Download as CSV" },
        { value: "pdf", displayName: "Download as PDF" },
      ],
    };
  },
  mounted() {
    this.getActivityType();
    this.getProjects();
  },
  methods: {
    downloadPendingAsCSV() {
      const items = this.cSVPendingPlots;
      const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
      const header = Object.keys(items[0]);
      const csv = [
        header.join(","), // header row first
        ...items.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(",")
        ),
      ].join("\r\n");

      const now = new Date(); // Current date and time
      const unixTimeInSeconds = Math.floor(now.getTime() / 1000);
      let anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      anchor.target = "_blank";
      anchor.download = "pending_plots" + unixTimeInSeconds + ".csv";
      anchor.click();
    },
    openModal() {
      var myModal = new bootstrap.Modal(
        document.getElementById("exampleModal")
      );
      myModal.show();
      this.isModalOpen = true; // Set modal open state to true
    },
    closeModal() {
      var myModal = new bootstrap.Modal(
        document.getElementById("exampleModal")
      );
      myModal.hide();
      // Set modal open state to false
    },
    downloadCSVFile() {
      const items = this.csv_data;
      if (items.length == 0) {
        alert("No Records Found");
        return false;
      }
      const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
      const header = Object.keys(items[0]);
      const csv = [
        header.join(","), // header row first
        ...items.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(",")
        ),
      ].join("\r\n");

      const now = new Date(); // Current date and time
      const unixTimeInSeconds = Math.floor(now.getTime() / 1000);
      let anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      anchor.target = "_blank";
      anchor.download = "activities_report" + unixTimeInSeconds + ".csv";
      anchor.click();
    },
    downloadPDFFile() {
      var img = new Image();
      img.src = require("@/assets/hosa.png");

      const plotsNames = this.plotsNames.join(",");
      const zoneNames = this.zoneNames.join(",");
      const startDate = this.startDate;
      const endDate = this.endDate;

      var doc = new jsPDF();
      doc.addImage(img, "PNG", 13, 4, 50, 14, { align: "left" });
      doc.setFontSize(16);
      doc.text("Activity Report", 160, 10, { align: "left" });
      doc.setFontSize(10);
      doc.text(`${startDate} - ${endDate}`, 160, 17, { align: "left" });
      doc.setFontSize(14);
      var zoneArr = doc.splitTextToSize(`Zone: ${zoneNames}`, 180, {
        align: "center",
      });
      doc.text(zoneArr, 13, 25);
      var plotArr = doc.splitTextToSize(`Plot: ${plotsNames}`, 180);
      let plotStringY = 30 + zoneArr.length * 5;
      doc.text(plotArr, 13, plotStringY);
      let suffix = 0;
      if (plotArr.length > 10) {
        suffix = 20;
      }
      let tabletStringY = suffix + plotStringY + plotArr.length * 5;

      doc.autoTable({
        html: "#temp-target",
        startY: tabletStringY,
        theme: "grid",
        showHead: "firstPage",
      });
      doc.save(`activity_report_${moment().format("DDMMYY")}.pdf`);
    },
    handleMenuItemClick(item) {
      if (item.value == "csv") {
        this.downloadCSVFile();
      }
      if (item.value == "pdf") {
        this.downloadPDFFile();
      }
    },
    getActivityType() {
      this.$axios.get("/activity-type").then((res) => {
        this.activityType.push({
          value: -1,
          label: "All",
        });
        res.data.data.forEach((cf) => {
          this.activityType.push({
            value: cf._id,
            label: cf.name,
          });
        });
      });
    },

    isSelected(option) {
      /* unfortunatelly following line does not work as VueMultiselect for some (strange) reason 
        fills the v-model array with copies instead of original objects contained in options
      */
      // return this.value.includes(option)
      return this.forms.activityTypeId.some((op) => op === option);
    },
    getProjects() {
      this.$axios.get("/projects").then((res) => {
        res.data.data.forEach((cf) => {
          this.projects.push({
            id: cf._id,
            text: cf.name,
          });
        });
      });
    },

    ProjectSelected({ id, text }) {
      let project_id = this.forms.ProjectId;
      this.zones = [];
      this.plots = [];
      this.forms.ZoneId = this.forms.PlotId = "";
      this.$refs.zoneSelect.clear();
      this.getZoneBasedOnProjectID(project_id);
    },
    ZoneChanged() {
      this.plots = [];
      this.forms.PlotId = "";
      if (this.forms.ZoneId.includes(-1)) {
        this.forms.ZoneId = this.zones.map((at) => {
          return at.value;
        });
      }

      this.getPlotBasedOnZoneID(this.forms.ZoneId);
    },
    ActivityTypeChanged() {
      if (this.forms.activityTypeId.includes(-1)) {
        this.forms.activityTypeId = this.activityType.map((at) => {
          if (at.id != -1) {
            return at.value;
          }
        });
      }
    },
    getZoneBasedOnProjectID(project_id) {
      this.zones = [];

      this.$axios.get("/zones/" + project_id).then((res) => {
        console.log(res.data.data);
        if (res.data.data.length > 0) {
          this.zones.push({
            value: -1,
            label: "All",
          });
          res.data.data.forEach((cf) => {
            this.zones.push({
              value: cf._id,
              label: cf.code,
            });
          });
        }
      });
    },
    getPlotBasedOnZoneID(zone_id) {
      let projectID = this.forms.ProjectId;
      console.log(zone_id);

      if (zone_id.includes(-1)) {
        zone_id = this.zones.map((z) => {
          if (z.value != -1) {
            return z.value;
          }
        });
        zone_id = zone_id.filter((z) => z != undefined);
      }

      console.log(zone_id);

      this.$axios
        .get("/plots", {
          params: {
            zoneId: zone_id,
            projectID: projectID,
          },
        })
        .then((res) => {
          if (res.data.data.length > 1) {
            res.data.data.forEach((cf) => {
              this.plots.push({
                id: cf._id,
                text: cf.code,
              });
            });
          }
        });
    },
    dateAndTime(value) {
      if (value) return moment(value).format("DD-MM-YYYY HH:MM:SS");
      else return "";
    },
    dateFormat(value) {
      if (value) return moment(value).format("DD-MM-YYYY");
      else return "";
    },
    prevPage() {
      if (this.page > 1) {
        this.page--;
        this.getData();
        this.isPreviousBtn = false;
      } else {
        this.isPreviousBtn = true;
      }
    },
    nextPage() {
      if (this.total_page >= this.page) {
        this.page++;
        this.getData();
        this.isNextBtn = false;
      } else {
        this.isNextBtn = true;
      }
    },
    getData() {
      let form = this.forms;
      let start_date = form.dates ? form.dates[0] : "";
      let end_date = form.dates ? form.dates[1] : "";
      let activityTypeId = form.activityTypeId;
      let ProjectId = form.ProjectId;
      let ZoneId = form.ZoneId;
      let PlotId = form.PlotId;
      if (ZoneId.includes(-1)) {
        ZoneId = this.zones.map((z) => {
          if (z.value != -1) {
            return z.value;
          }
        });
        ZoneId = ZoneId.filter((z) => z != undefined);
      }

      if (activityTypeId.includes(-1)) {
        activityTypeId = this.activityType.map((z) => {
          if (z.value != -1) {
            return z.value;
          }
        });
        activityTypeId = activityTypeId.filter((z) => z != undefined);
      }
      if (
        activityTypeId == "" ||
        ProjectId == "" ||
        ZoneId == "" ||
        start_date == "" ||
        end_date == ""
      ) {
        alert("Please fill all the paramters");
        return;
      }

      this.isLoading = true;

      this.$axios
        .post("/reports/activities", {
          limit: this.limit,
          page: this.page,
          activityTypeId: activityTypeId,
          ProjectId: ProjectId,
          ZoneId: ZoneId,
          PlotId: PlotId,
          start_date: start_date,
          end_date: end_date,
        })
        .then((res) => {
          if (res.data.success) {
            this.isLoading = false;
            this.isDataLoaded = true;
            this.data = res.data.data.activity;
            this.totalPlots = res.data.data.totalPlot;
            this.cSVPendingPlots = res.data.data.pendingPlots;
            this.completedPlotActivities =
              res.data.data.completedPlotActivities;
            this.pendingPlotActivities = res.data.data.pendingPlotActivities;
            this.activitiesNames = res.data.data.activitiesNames;
            this.plotsNames = res.data.data.plotsNames;
            this.zoneNames = res.data.data.zoneNames;
            this.startDate = res.data.data.startDate;
            this.endDate = res.data.data.endDate;
            this.csv_data = this.data.map((d) => {
              return {
                Project: d.project,
                Zone: d.zone,
                "Plot no": d.plot,
                "Activity type": d.activityType,
                "Activity details": d.activityDetails,
                Date: this.dateFormat(d.dateOfCompletion),
              };
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;

          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          this.LoadingMsg = error.message;
          console.log(error.config);
        });
    },
    dateAndTime(value) {
      if (value) return moment(value).format("DD-MM-YYYY HH:MM:SS");
      else return "";
    },
    dateFormat(value) {
      if (value) return moment(value).format("DD-MM-YYYY");
      else return "";
    },
    getBookingStatusById(id) {
      switch (id) {
        case 1:
          return "Confirmed";
          break;
        case 2:
          return "Refund Pending";
          break;
        case 3:
          return "Cancelled";
          break;
        case 4:
          return "Cancelled";
          break;
      }
    },
    resetFormData() {
      this.$router.go();
    },
  },
};
</script>
  <style scoped>
.dp__input {
  border-color: black !important;
}

.select2-selection__arrow {
  top: 6px !important;
}

.select2-selection--single {
  border: 2px solid #d1d5db !important;
  font-size: 1rem !important;
  height: 2.5em !important;
  border-radius: 5px !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 40px !important;
}
td {
  white-space: nowrap;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.word-wrap {
  word-wrap: break-all;
}

.select2-selection__choice {
  background: #56a842 !important;
  color: white;
}

td {
  white-space: normal !important;
  word-wrap: break-word;
}
table {
  table-layout: fixed;
}

.table thead tr th {
  font-size: 16px;
}

.table tbody tr td {
  font-size: 16px;
  color: grey;
}
.mr-2 {
  margin-right: 0.5rem;
}
</style>
  